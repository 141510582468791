//Regular input field
export const inputField =
  "  font-sans text-sm text-black border-b-2 border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none "
//Regular input field
export const inputFieldBlue =
  "  font-sans text-sm text-blue border-b-2 border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none placeholder-blue "
//Input field error
export const inputFieldError =
  " font-sans text-sm text-black border-b-2 border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//Message sized input field
export const inputFieldMessage =
  "  font-sans text-xs text-black border-blue p-2 mt-2 w-full h-40  focus:ring-0 focus:ring-blue focus:border-0 peer focus:outline-none "
//Message sized input field error
export const inputFieldMessageError =
  " font-sans text-sm text-black border-b-2 border-blue p-2 mt-2 w-full h-40 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//Regular phonenumber field
export const inputFieldPhoneNumber =
  " border-b-2 border-blue font-sans text-sm text-black hover:border-2 hover:-mb-0.5 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none "
//Input phonenumber error field
export const inputFieldPhoneNumberError =
  " border-b-2 border-blue font-sans text-sm text-black hover:border-2 hover:-mb-0.5 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
// Modal input field
export const inputFieldModal =
  " font-sans text-xs text-black border-b-2 border-blue p-2 mt-2 w-full h-8 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none "
//Modal input field error
export const inputFieldErrorModal =
  " font-sans text-xs text-black border-b-2 border-blue p-2 mt-2 w-full h-8 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//Modal input phonenumber field
export const inputFieldPhoneNumberModal =
  " border-b-2 border-blue font-sans text-xs text-black hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none "
//Modal input phonenumber error field
export const inputFieldPhoneNumberErrorModal =
  " border-b-2 border-blue font-sans text-xs text-black hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//SMS input field
export const smsInputField =
  "  font-sans text-sm text-black p-2 mt-2 w-full h-40 focus:outline-none "
// Input field error for case and messages
export const inputFieldErrorMessage =
  " font-sans text-sm text-black border-2 border-orange-dark p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
// Input field case message
export const InputCase =
  " font-sans text-xs text-black border-blue border-b-2 p-2 mt-2 w-full h-40 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none pb-6 "
//Input field case message error
export const InputCaseError =
  " font-sans text-xs text-black border-orange-dark border-2 p-2 mt-2 w-full h-40 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none pb-6 "
// Select value from dropdown
export const selectValue =
  " font-sans text-sm text-black border-b-2 bg-white border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-blue focus:border-2 focus:outline-none "
// Select value from dropdown
export const selectValueError =
  " font-sans text-sm text-black border-2 bg-white border-orange-dark p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-orange-dark focus:border-0 focus:outline-none "
//Input field error
export const inputFieldErrorAmount =
  " font-sans text-sm text-black border-2 border-orange-dark p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
