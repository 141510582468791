import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter, RepresentativeProps } from "../../Utils/Types"
import { AgeFilter } from "./AgeFilter"
import { BirthyearFilter } from "./BirthyearFilter"
import { BranchFilter } from "./BranchFilter"
import { DistrictFilter } from "./DistrictFilter"
import { PostPlacesFilter } from "./PostPlacesFilter"
import { PostCodesFilter } from "./PostCodesFilter"
import { MembershipFilter } from "./MembershipFilter"
import { CommitteeFilter } from "./CommitteeFilter"
import { ActiveRolesFilter } from "./ActiveRolesFilter"
import { InactiveRolesFilter } from "./InactiveRolesFilter"
import { GenderFilter } from "./GenderFilter"

export const Filters = ({
  props,
  filterProps,
  setFilterProps,
  selectedGender,
  setSelectedGender,
  selectedBranch,
  setSelectedBranch,
  localBranches,
  selectedDistrict,
  setSelectedDistrict,
  districts,
  selectedPostPlace,
  setSelectedPostPlace,
  postPlaces,
  selectedPostCodes,
  setSelectedPostCodes,
  postCodes,
  selectedCommittee,
  setSelectedCommittee,
  selectedActiveRoles,
  setSelectedActiveRoles,
  selectedInactiveRoles,
  setSelectedInactiveRoles,
}: {
  props: RepresentativeProps
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedGender: any
  setSelectedGender: Dispatch<SetStateAction<any>>
  selectedBranch: any
  setSelectedBranch: Dispatch<SetStateAction<any>>
  localBranches: any
  selectedDistrict: any
  setSelectedDistrict: Dispatch<SetStateAction<any>>
  districts: any
  selectedPostPlace: any
  setSelectedPostPlace: Dispatch<SetStateAction<any>>
  postPlaces: any
  selectedPostCodes: any
  setSelectedPostCodes: Dispatch<SetStateAction<any>>
  postCodes: any
  selectedCommittee: any
  setSelectedCommittee: Dispatch<SetStateAction<any>>
  selectedActiveRoles: any
  setSelectedActiveRoles: Dispatch<SetStateAction<any>>
  selectedInactiveRoles: any
  setSelectedInactiveRoles: Dispatch<SetStateAction<any>>
}) => {
  return (
    <>
      <div />
      <AgeFilter filterProps={filterProps} setFilterProps={setFilterProps} />
      <BirthyearFilter
        filterProps={filterProps}
        setFilterProps={setFilterProps}
      />
      <GenderFilter
        filterProps={filterProps}
        setFilterProps={setFilterProps}
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
      />
      <div className=" border-b-2 border-blue mt-6 mb-6" />
      {props.committeeType === "Valgkomite" &&
      props.branchType === 778380001 ? (
        <BranchFilter
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
          localBranches={localBranches}
        />
      ) : null}
      <DistrictFilter
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
        districts={districts}
      />
      <PostPlacesFilter
        selectedPostPlace={selectedPostPlace}
        setSelectedPostPlace={setSelectedPostPlace}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
        postPlaces={postPlaces}
      />
      <PostCodesFilter
        selectedPostCodes={selectedPostCodes}
        setSelectedPostCodes={setSelectedPostCodes}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
        postCodes={postCodes}
      />
      <div className=" border-b-2 border-blue mt-4 mb-6" />
      <MembershipFilter
        filterProps={filterProps}
        setFilterProps={setFilterProps}
      />
      <CommitteeFilter
        selectedCommittee={selectedCommittee}
        setSelectedCommittee={setSelectedCommittee}
        filterProps={filterProps}
        setFilterProps={setFilterProps}
      />
      {/* Hide if InactiveRolesFiler have data */}
      {filterProps?.postStatus === 1 ? null : (
        <ActiveRolesFilter
          selectedActiveRoles={selectedActiveRoles}
          setSelectedActiveRoles={setSelectedActiveRoles}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
        />
      )}
      {/* Hide if ActiveRolesFilter have data */}
      {filterProps?.postStatus === 0 ? null : (
        <InactiveRolesFilter
          selectedInactiveRoles={selectedInactiveRoles}
          setSelectedInactiveRoles={setSelectedInactiveRoles}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
        />
      )}
    </>
  )
}
