import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { underlineButton, down, right, saveButton } from "../Styling/Buttons"
import { navigate } from "gatsby"
import { inputField } from "../Styling/InputField"
import { useForm } from "react-hook-form"
import { QueryClient, useMutation } from "react-query"
import { postApi } from "../Utils/WebApi-utils"
import { useMsal } from "@azure/msal-react"
import { toast } from "react-toastify"
import { Filter, RepresentativeProps } from "../Utils/Types"
import LoadingModal from "./LoadingModal"

const queryClient = new QueryClient()

const ConfirmMessageModal = ({
  props,
  setProps,
  filterProps,
  setFilterProps,
  openModal,
  setOpenModal,
  amount,
  price,
  totalCount,
  noSmsCount,
  noEmailCount,
  isEmail,
  smsString,
  emailString,
  subjectString,
  fetchXml,
  setSelectedGender,
  setSelectedCommittee,
  setSelectedPostPlace,
  setSelectedPostCodes,
  setSelectedBranch,
  setSelectedDistrict,
  setSelectedActiveRoles,
  setSelectedInactiveRoles,
}: {
  props: RepresentativeProps
  setProps: Dispatch<SetStateAction<RepresentativeProps>>
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
  amount: number
  price: number
  totalCount: number
  noSmsCount: number
  noEmailCount: number
  isEmail: boolean
  smsString: string
  emailString: string
  subjectString: string
  fetchXml: string
  setSelectedGender: any
  setSelectedCommittee: any
  setSelectedPostPlace: any
  setSelectedPostCodes: any
  setSelectedBranch: any
  setSelectedDistrict: any
  setSelectedActiveRoles: any
  setSelectedInactiveRoles: any
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const [inputValue, setInputValue] = useState("")
  const [sendDisabled, setSendDisabled] = useState(true)
  const [cancelDisabled, setCancelDisabled] = useState(false)
  const { handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const PostMessage = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefQueries/RepresentativeSendOff",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        {
          toast.success("Meldingen ble sendt", {
            bodyClassName: "bg-blue text-white",
            theme: "colored",
            style: { backgroundColor: "#004C97" },
          }),
            setProps({ ...props, render: "representative", queryId: "" }),
            queryClient.invalidateQueries(["getQueries"]),
            console.log(data)
          setSelectedGender([])
          setSelectedCommittee([])
          setSelectedPostPlace([])
          setSelectedPostCodes([])
          setSelectedBranch([])
          setSelectedDistrict([])
          setSelectedActiveRoles([])
          setSelectedInactiveRoles([])
          setFilterProps({
            ...filterProps,
            ageFrom: null,
            ageTo: null,
            birthYearFrom: null,
            birthYearTo: null,
            postCodes: null,
            postPlaces: null,
            branch: null,
            district: null,
            committteeTypes: null,
            postRoles: null,
            postStatus: null,
            memberFrom: null,
            memberTo: null,
            genderCodes: null,
            searchParameter: null,
          })
        }
      },
      onError: data => {
        setLoading(false)
        setCancelDisabled(false)
        setSendDisabled(false)
        toast.error("Noe gikk galt med meldingen!")
      },
    }
  )

  let smsObject: any = {
    localBranchId: props.branchId,
    test: false,
    description: smsString,
    subject: "sms",
    userQuery: props.queryId,
    fetchXml: fetchXml,
    channel: "sms",
  }

  let emailObject: any = {
    localBranchId: props.branchId,
    test: false,
    description: emailString,
    subject: subjectString,
    userQuery: props.queryId,
    fetchXml: fetchXml,
    channel: "email",
  }

  const onSubmitMessage = () => {
    if (isEmail === true) {
      setLoading(true)
      setCancelDisabled(true)
      setSendDisabled(true)
      PostMessage.mutate(emailObject)
    } else {
      setLoading(true)
      setCancelDisabled(true)
      setSendDisabled(true)
      PostMessage.mutate(smsObject)
    }
  }

  useEffect(() => {
    if (inputValue === "ja") {
      setSendDisabled(false)
    } else {
      setSendDisabled(true)
    }
  }, [inputValue])

  return (
    <div>
      {loading === true && <LoadingModal />}
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          auto-reopen="true"
          className=" fixed z-10 inset-0 overflow-y-auto p-18"
          onClose={() => {}}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-8 pb-20 text-center backdrop-blur sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-gray border-blue border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="text-start">
                    <Dialog.Title as="h3" className="text-base font-medium ">
                      Bekreft at du vil sende meldingene nå
                    </Dialog.Title>
                  </div>
                  <div>
                    {isEmail ? (
                      <div>
                        <p>
                          Du er i ferd med å sende e-post til{" "}
                          <span className=" font-bold ">
                            {totalCount - noEmailCount} mottakere
                          </span>
                          . E-poster kan ikke angres eller trekkes tilbake
                          etterpå.
                        </p>
                        <ul>
                          <li>
                            Antall som kan motta e-posten:{" "}
                            <span className=" font-bold ">
                              {totalCount - noEmailCount}
                            </span>
                          </li>
                          <li className=" mb-0 ">
                            Antall som <span className=" font-bold ">ikke</span>{" "}
                            kan motta e-posten:{" "}
                            <span className=" font-bold ">{noEmailCount}</span>
                          </li>
                          <span className=" text-[16px] ">
                            (Antall personer som har mottatt maksimalt antall
                            utsendinger i dag)
                          </span>
                        </ul>
                      </div>
                    ) : (
                      <div>
                        <p>
                          Du er i ferd med å sende SMS til{" "}
                          <span className=" font-bold ">
                            {totalCount - noSmsCount} mottakere
                          </span>
                          . SMS-er kan ikke angres eller trekkes tilbake
                          etterpå.
                          <span>
                            {" "}
                            Dette koster oss{" "}
                            <span className=" font-bold ">{price} kr</span>.
                          </span>
                        </p>
                        <ul>
                          <li>
                            Antall som kan motta sms:{" "}
                            <span className=" font-bold ">
                              {totalCount - noSmsCount}
                            </span>
                          </li>
                          <li className=" mb-0 ">
                            Antall som <span className=" font-bold ">ikke</span>{" "}
                            kan motta sms:{" "}
                            <span className=" font-bold ">{noSmsCount}</span>
                          </li>
                          <span className=" text-[16px]  ">
                            (Antall personer som har mottatt maksimalt antall
                            utsendinger i dag)
                          </span>
                        </ul>
                      </div>
                    )}
                    <p>
                      Skriv «<span className=" font-bold ">Ja</span>» for å
                      bekrefte at du ønsker å sende meldingene nå.
                    </p>
                  </div>
                  <div>
                    <input
                      placeholder="Ja"
                      className={inputField}
                      onChange={e =>
                        setInputValue(e.target.value.toLowerCase())
                      }
                    ></input>
                  </div>
                  <form
                    name="sendMessage"
                    method="post"
                    onSubmit={handleSubmit(onSubmitMessage)}
                  >
                    <div className="flex flex-row justify-between md:justify-around mt-2">
                      <div>
                        <button
                          disabled={cancelDisabled}
                          type="button"
                          className={
                            underlineButton +
                            " disabled:opacity-50 disabled:transition-none "
                          }
                          onClick={() => setOpenModal(false)}
                        >
                          Avbryt
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={sendDisabled}
                          type="submit"
                          className={
                            sendDisabled
                              ? saveButton + " text-white mr-0 opacity-30 "
                              : saveButton + down + " mr-0 "
                          }
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default ConfirmMessageModal
