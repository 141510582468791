import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query"
import { Link, navigate } from "gatsby"
import { Gender, GetPortalSecurity, GetProfile } from "../Utils/ReactQueries"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import "react-toastify/dist/ReactToastify.css"
import moment from "moment"
import LoadingModal from "../Modal/LoadingModal"
import { right, saveButton, underlineButton } from "../Styling/Buttons"
import { Filter, Post, RepresentativeProps } from "../Utils/Types"

const RepresentativeContent = ({
  props,
  setProps,
  filterProps,
  setFilterProps,
}: {
  props: RepresentativeProps
  setProps: Dispatch<SetStateAction<RepresentativeProps>>
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const queryClient = useQueryClient
  const checkPortalSecurity: UseQueryResult<Post[]> = GetPortalSecurity(
    accounts,
    inProgress,
    instance
  )

  const [selectedOraganization, setSelectedOraganization] = useState(false)
  const [loading, setLoading] = useState(false)

  const myProfile = GetProfile(accounts, inProgress, instance)

  console.log(myProfile)

  console.log(filterProps)

  useEffect(() => {
    if (
      checkPortalSecurity.isRefetching === true ||
      myProfile.isRefetching === true
    ) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [
    checkPortalSecurity.isSuccess,
    checkPortalSecurity.isRefetching,
    myProfile.isSuccess,
    myProfile.isRefetching,
  ])

  useEffect(() => {
    if (checkPortalSecurity.isSuccess && myProfile.isSuccess) {
      if (checkPortalSecurity?.data == undefined) {
        checkPortalSecurity.refetch()
        myProfile.refetch()
      } else if (
        (checkPortalSecurity?.data?.length == 0 &&
          checkPortalSecurity.isRefetching == false) ||
        (checkPortalSecurity?.data?.length == null &&
          checkPortalSecurity.isRefetching == false)
      ) {
        navigate("/")
      }
    }
  }, [checkPortalSecurity.isSuccess, checkPortalSecurity.isRefetching])

  useEffect(() => {
    if (props.branchId == "") {
      setSelectedOraganization(false)
    } else {
      setSelectedOraganization(true)
    }
  }, [checkPortalSecurity.isSuccess])

  console.log(props)
  console.log(checkPortalSecurity)

  const prevPage = () => {
    setSelectedOraganization(false)
    setProps({
      ...props,
      branchId: "",
      organizationNumber: 0,
      name: "",
      fromDate: "",
      toDate: "",
      newMembersLastXDays: 0,
      terminationsLastXDays: 0,
      bankAccount: 0,
      email: "",
      totalMembers: 0,
      roleName: "",
    })
  }

  return (
    <div>
      {myProfile.isLoading ||
      checkPortalSecurity.isLoading ||
      loading == true ? (
        <LoadingModal />
      ) : (
        <div>
          {selectedOraganization ? (
            <div>
              {/* <ToastContainer autoClose={20000} position="bottom-center" /> */}
              <div className="px-8 lg:px-32">
                <div className=" border-b-2 border-blue pt-2 mb-0">
                  <button
                    onClick={prevPage}
                    className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Velg lokallag/min side
                  </button>
                </div>
              </div>
              <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
                <div className="lg:min-w-[50%] lg:max-w-[50%]">
                  <div className="flex flex-col">
                    <h1 className="font-serif text-blue mb-6 text-xl font-normal">
                      {props?.name}
                    </h1>
                    <p className="font-sans text-sm my-2">
                      Org. nr.{" "}
                      <span className="font-bold">
                        {props?.organizationNumber}
                      </span>
                    </p>
                    <p className="font-sans text-sm my-2">
                      Kontonummer{" "}
                      <span className="font-bold">{props?.bankAccount}</span>
                    </p>
                    {props?.toDate === undefined ? (
                      <p className="font-sans text-sm my-2">
                        Du har vært {props?.roleName.toLowerCase()} siden{" "}
                        <span className="font-bold">
                          {moment(props?.fromDate).format("DD.MM.YY")}
                        </span>
                      </p>
                    ) : (
                      <p className="font-sans text-sm my-2">
                        Du er {props?.roleName.toLowerCase()} i perioden{" "}
                        <span className="font-bold">
                          {moment(props?.fromDate).format("DD.MM.YY")} -{" "}
                          {moment(props?.toDate).format("DD.MM.YY")}
                        </span>
                      </p>
                    )}
                    <p className="font-sans text-sm mt-2 mb-6">
                      E-post <label className="font-bold">{props?.email}</label>
                    </p>
                    <div className=" border-t-2 border-b-2 border-blue flex flex-col">
                      <div className="mt-2 mb-1 flex justify-between">
                        <p className="font-sans font-normal mb-0">
                          Nye medlemmer siste 30 dager{" "}
                        </p>
                        <p className="font-sans font-bold mb-0">
                          {props?.newMembersLastXDays} stk.
                        </p>
                      </div>
                      <div className="mb-4 flex justify-between">
                        <p className="font-sans font-normal mb-0">
                          Utmeldte siste 30 dager{" "}
                        </p>
                        <p className="font-sans font-bold mb-0">
                          {props?.terminationsLastXDays} stk.
                        </p>
                      </div>
                      <div className="mb-2 flex justify-between">
                        <p className="font-sans font-bold mb-0">
                          Totalt antall medlemmer{" "}
                        </p>
                        <p className="font-sans font-bold mb-0">
                          {props?.totalMembers} stk.
                        </p>
                      </div>
                    </div>
                    <div className="mb-4 mt-6">
                      <button
                        onClick={() => {
                          setProps({
                            ...props,
                            render: "memberList",
                            queryId: process.env.GATSBY_APP_QUERYID_MEMBERS,
                            queryDescription:
                              "$WebApi.Tillitsvalgte.01 aktive medlemmer",
                          })
                          setFilterProps({
                            ...filterProps,
                            orderField: "fullname",
                            descending: false,
                          })
                        }}
                        className={saveButton + right}
                      >
                        Medlemsoversikt og utsendinger
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
              <div className="lg:min-w-[50%] lg:max-w-[50%]">
                <div className="flex flex-col space-y-6">
                  <h1 className="font-serif text-blue mb-4 text-xl font-normal">
                    Hei {myProfile?.data?.firstName}!
                  </h1>
                  <p className="font-sans mb-4 text-base">
                    Styr en av dine organisasjoner eller gå til Min side.
                  </p>
                  <div className="flex flex-row mt-2 mb-4 ">
                    <Link to="/">
                      <button className={saveButton + right + " mb-2 mt-2"}>
                        Gå til Min side
                      </button>
                    </Link>
                  </div>
                  <div className=" border-b-2 border-blue mt-4"></div>
                  <div className="flex flex-col pt-4">
                    <h2 className="font-serif text-blue mb-4 text-xl font-normal">
                      Mine organisasjoner
                    </h2>
                    <div className="space-y-2 border-t-2 border-blue flex flex-col py-2">
                      {checkPortalSecurity?.data?.map((items, i) => (
                        <button
                          key={i}
                          className="flex flex-col"
                          onClick={() => {
                            setSelectedOraganization(true)
                            setProps({
                              ...props,
                              branchId: items?.committee?.branchId?.branchId,
                              branchType:
                                items?.committee?.branchId?.branchType,
                              organizationNumber:
                                items?.committee?.branchId?.organizationNumber,
                              name: items?.committee?.branchId?.name,
                              fromDate: items?.fromDate,
                              toDate: items?.toDate,
                              newMembersLastXDays:
                                items?.committee?.branchId?.newMembersLastXDays,
                              terminationsLastXDays:
                                items?.committee?.branchId
                                  ?.terminationsLastXDays,
                              bankAccount:
                                items?.committee?.branchId?.bankAccount,
                              email: items?.committee?.branchId?.email,
                              totalMembers:
                                items?.committee?.branchId?.totalMembers,
                              roleName: items?.role?.roleName,
                              committeeType:
                                items?.committee?.committeeType
                                  ?.committeeTypeName,
                            })
                            setFilterProps({
                              ...filterProps,
                              postId: items?.postId,
                            })
                          }}
                        >
                          <label className=" text-blue w-full hover:cursor-pointer hover:text-orange-dark flex justify-between border-blue border-b-2 pb-2 font-sans items-center group peer transition-all ">
                            {items?.committee?.branchId?.name}
                          </label>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default RepresentativeContent
