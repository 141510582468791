import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter } from "../../Utils/Types"
import { Listbox, Transition } from "@headlessui/react"
import { PlusIcon } from "../../Graphics/PlusIcon"
import { XIcon } from "../../Graphics/XIcon"
import { EmptyCheckbox } from "../../Graphics/EmptyCheckbox"
import { ActiveCheckbox } from "../../Graphics/ActiveCheckbox"

export const BranchFilter = ({
  filterProps,
  setFilterProps,
  selectedBranch,
  setSelectedBranch,
  localBranches,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedBranch: any
  setSelectedBranch: Dispatch<SetStateAction<any>>
  localBranches: any
}) => {
  const removeFromList = value => {
    setSelectedBranch(prevSelectedBranch =>
      prevSelectedBranch.filter(branch => branch.branchId !== value.branchId)
    )
  }

  useEffect(() => {
    const id = selectedBranch?.map(branch => branch.branchId)
    setFilterProps({ ...filterProps, branch: id?.join(",") })

    if (selectedBranch == "") {
      setFilterProps({ ...filterProps, branch: null })
    }
  }, [selectedBranch])

  return (
    <div className=" flex flex-col relative mt-4 ">
      <Listbox value={selectedBranch} onChange={setSelectedBranch} multiple>
        <Listbox.Button className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 border-b-2 border-blue ">
          <span>Filtrer på lokallag</span>
          <span>
            <PlusIcon />
          </span>
        </Listbox.Button>
        <Listbox.Options className=" h-auto overflow-y-scroll max-h-80 border-2 border-blue pt-4 bg-white absolute z-10 w-full top-12 ">
          {localBranches?.data?.map(branch => (
            <Listbox.Option
              className={({ active }) =>
                `flex text-blue px-4 hover:cursor-pointer ${
                  active ? "bg-gray-dark" : "bg-white"
                }`
              }
              key={branch?.branchId}
              value={branch}
            >
              {({ selected }) => (
                <>
                  {selected ? (
                    <span className="mr-4">
                      <ActiveCheckbox />
                    </span>
                  ) : (
                    <span className="mr-4">
                      <EmptyCheckbox />
                    </span>
                  )}
                  {branch?.name}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
        <div className="mt-4">
          {selectedBranch?.map(branch => (
            <button
              onClick={() => removeFromList(branch)}
              className=" mr-2 mb-2 px-2 py-1 text-xs bg-blue text-white w-auto hover:opacity-80"
              key={branch?.branchId}
              value={branch?.branchId}
            >
              <div className="flex items-center">
                <span>{branch?.name}</span>
                <span>
                  <XIcon />
                </span>
              </div>
            </button>
          ))}
        </div>
      </Listbox>
    </div>
  )
}
