import React, { Dispatch, SetStateAction, useEffect } from "react"
import { inputField } from "../../Styling/InputField"
import { Filter } from "../../Utils/Types"

export const BirthyearFilter = ({
  filterProps,
  setFilterProps,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
}) => {
  useEffect(() => {
    if (Number.isNaN(filterProps?.birthYearFrom)) {
      setFilterProps({ ...filterProps, birthYearFrom: null })
    }

    if (Number.isNaN(filterProps?.birthYearTo)) {
      setFilterProps({ ...filterProps, birthYearTo: null })
    }
  }, [filterProps?.birthYearFrom, filterProps?.birthYearTo])

  return (
    <div className="pt-4 ">
      <p className="mb-1">Fødselsår</p>
      <div className=" flex flex-row space-x-2 flex-wrap ">
        <p className="self-center mb-0">Fra</p>
        <input
          type="number"
          defaultValue={
            filterProps?.birthYearFrom === null
              ? ""
              : filterProps?.birthYearFrom
          }
          onInput={e => {
            setFilterProps({
              ...filterProps,
              birthYearFrom: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-16 text-center"
          }
        ></input>
        <p className="self-center mb-0">til</p>
        <input
          type="number"
          defaultValue={
            filterProps?.birthYearTo === null ? "" : filterProps?.birthYearTo
          }
          onInput={e => {
            setFilterProps({
              ...filterProps,
              birthYearTo: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-16 text-center"
          }
        ></input>
      </div>
    </div>
  )
}
