import React from "react"
import { DownArrow } from "../Graphics/DownArrow"
import { UpArrow } from "../Graphics/UpArrow"

export const FilterSortButton = ({
  props,
  propValue,
  setProps,
  name,
  orderFieldValue,
  descendingValue,
}) => {
  return (
    <button
      onClick={() => {
        propValue === orderFieldValue
          ? setProps({
              ...props,
              orderField: orderFieldValue,
              descending: !props.descending,
            })
          : setProps({
              ...props,
              orderField: orderFieldValue,
              descending: descendingValue,
            })
      }}
      className={
        propValue === orderFieldValue
          ? "  font-semibold mb-2 flex flex-row hover:text-blue "
          : " mb-2 hover:text-blue "
      }
    >
      {name}
      {propValue === orderFieldValue ? (
        props.descending ? (
          <DownArrow />
        ) : (
          <UpArrow />
        )
      ) : null}
    </button>
  )
}
