import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter } from "../../Utils/Types"
import { Listbox, Transition } from "@headlessui/react"
import { PlusIcon } from "../../Graphics/PlusIcon"
import { XIcon } from "../../Graphics/XIcon"
import { EmptyCheckbox } from "../../Graphics/EmptyCheckbox"
import { ActiveCheckbox } from "../../Graphics/ActiveCheckbox"

export const DistrictFilter = ({
  filterProps,
  setFilterProps,
  selectedDistrict,
  setSelectedDistrict,
  districts,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedDistrict: any
  setSelectedDistrict: Dispatch<SetStateAction<any>>
  districts: any
}) => {
  const removeFromList = value => {
    console.log(value)
    setSelectedDistrict(prevSelectedDistrict =>
      prevSelectedDistrict.filter(district => district !== value)
    )
  }

  useEffect(() => {
    const names = selectedDistrict?.map(district => district)
    setFilterProps({ ...filterProps, district: names?.join(",") })

    if (selectedDistrict == "") {
      setFilterProps({ ...filterProps, district: null })
    }
  }, [selectedDistrict])

  return (
    <div className=" flex flex-col relative ">
      <Listbox value={selectedDistrict} onChange={setSelectedDistrict} multiple>
        <Listbox.Button className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 border-b-2 border-blue ">
          <span>Filtrer på kommune</span>
          <span>
            <PlusIcon />
          </span>
        </Listbox.Button>
        <Listbox.Options className=" h-auto overflow-y-scroll max-h-80 border-2 border-blue pt-4 bg-white absolute z-10 w-full top-12 ">
          {districts?.map(district => (
            <Listbox.Option
              className={({ active }) =>
                `flex text-blue px-4 hover:cursor-pointer ${
                  active ? "bg-gray-dark" : "bg-white"
                }`
              }
              key={district}
              value={district}
            >
              {({ selected }) => (
                <>
                  {selected ? (
                    <span className="mr-4">
                      <ActiveCheckbox />
                    </span>
                  ) : (
                    <span className="mr-4">
                      <EmptyCheckbox />
                    </span>
                  )}
                  {district}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
        <div className="mt-4">
          {selectedDistrict?.map(district => (
            <button
              onClick={() => removeFromList(district)}
              className=" mr-2 mb-2 px-2 py-1 text-xs bg-blue text-white w-auto hover:opacity-80"
              key={district}
              value={district}
            >
              <div className="flex items-center">
                <span>{district}</span>
                <span>
                  <XIcon />
                </span>
              </div>
            </button>
          ))}
        </div>
      </Listbox>
    </div>
  )
}
