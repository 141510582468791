import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter } from "../../Utils/Types"
import { XIcon } from "../../Graphics/XIcon"
import { EmptyCheckbox } from "../../Graphics/EmptyCheckbox"
import { ActiveCheckbox } from "../../Graphics/ActiveCheckbox"
import { DownArrow } from "../../Graphics/DownArrow"
import { Combobox } from "@headlessui/react"

export const PostPlacesFilter = ({
  filterProps,
  setFilterProps,
  selectedPostPlace,
  setSelectedPostPlace,
  postPlaces,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedPostPlace: any
  setSelectedPostPlace: Dispatch<SetStateAction<any>>
  postPlaces: any
}) => {
  const [query, setQuery] = useState("")

  const filteredPostalPlace =
    query === ""
      ? postPlaces
      : postPlaces.filter(postPlaces =>
          postPlaces
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )

  const removeFromList = value => {
    console.log(value)
    setSelectedPostPlace(prevSelectedPostPlaces =>
      prevSelectedPostPlaces.filter(postPlaces => postPlaces !== value)
    )
  }

  useEffect(() => {
    const postPlaces = selectedPostPlace?.map(postPlaces => postPlaces)
    setFilterProps({ ...filterProps, postPlaces: postPlaces?.join(",") })

    if (selectedPostPlace == "") {
      setFilterProps({ ...filterProps, postPlaces: null })
    }
  }, [selectedPostPlace])

  return (
    <div className=" flex flex-col relative ">
      <Combobox
        value={selectedPostPlace}
        onChange={setSelectedPostPlace}
        multiple
      >
        <div className="flex">
          <Combobox.Input
            onChange={e => setQuery(e.target.value)}
            className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 w-full focus-visible:outline-none placeholder-black border-b-2 border-blue"
            placeholder="Søk etter poststed"
          ></Combobox.Input>
          <Combobox.Button
            onClick={() => setQuery("")}
            className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 hover:opacity-80 border-b-2 border-blue"
          >
            <span>
              <DownArrow />
            </span>
          </Combobox.Button>
        </div>
        <Combobox.Options className=" h-auto overflow-y-scroll max-h-80 border-2 border-blue pt-4 bg-white absolute z-10 w-full top-12 ">
          {filteredPostalPlace?.map(postPlaces => (
            <Combobox.Option
              className={({ active }) =>
                `flex text-blue px-4 hover:cursor-pointer ${
                  active ? "bg-gray-dark" : "bg-white"
                }`
              }
              key={postPlaces}
              value={postPlaces}
            >
              {({ selected }) => (
                <>
                  {selected ? (
                    <span className="mr-4">
                      <ActiveCheckbox />
                    </span>
                  ) : (
                    <span className="mr-4">
                      <EmptyCheckbox />
                    </span>
                  )}
                  {postPlaces}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
        <div className="mt-4">
          {selectedPostPlace?.map(postPlaces => (
            <button
              onClick={() => removeFromList(postPlaces)}
              className=" mr-2 mb-2 px-2 py-1 text-xs bg-blue text-white w-auto hover:opacity-80"
              key={postPlaces}
              value={postPlaces}
            >
              <div className="flex items-center">
                <span>{postPlaces}</span>
                <span>
                  <XIcon />
                </span>
              </div>
            </button>
          ))}
        </div>
      </Combobox>
    </div>
  )
}
