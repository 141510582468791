import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import React, { Component, useState } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import MembersListContent from "../components/MyPage/MembersList"
import RepresentativeContent from "../components/MyPage/Representative"
import SendMessageContent from "../components/MyPage/SendMessage"
import { loginRequest } from "../components/Security/authConfig"
import { ErrorComponent } from "../components/Utils/ErrorComponent"
import { Filter, RepresentativeProps } from "../components/Utils/Types"

const queryClient = new QueryClient()

const RepresentativePageContent = () => {
  const [props, setProps] = useState<RepresentativeProps>({
    queryId: "",
    branchId: "",
    branchType: 0,
    organizationNumber: 0,
    name: "",
    fromDate: "",
    toDate: "",
    portalSecurityRole: "",
    render: "representative",
    queryDescription: "",
    newMembersLastXDays: 0,
    terminationsLastXDays: 0,
    bankAccount: 0,
    email: "",
    totalMembers: 0,
    roleName: "",
    committeeType: "",
  })

  const [filterProps, setFilterProps] = useState<Filter>({
    postId: "",
    ageFrom: null,
    ageTo: null,
    birthYearFrom: null,
    birthYearTo: null,
    postCodes: null,
    postPlaces: null,
    branch: null,
    district: null,
    committteeTypes: null,
    postRoles: null,
    postStatus: null,
    memberFrom: null,
    memberTo: null,
    genderCodes: null,
    searchParameter: null,
    orderField: null,
    descending: false,
  })

  const [selectedGender, setSelectedGender] = useState([])
  const [selectedCommittee, setSelectedCommittee] = useState([])
  const [selectedPostPlace, setSelectedPostPlace] = useState([])
  const [selectedPostCodes, setSelectedPostCodes] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState([])
  const [selectedBranch, setSelectedBranch] = useState([])
  const [selectedActiveRoles, setSelectedActiveRoles] = useState([])
  const [selectedInactiveRoles, setSelectedInactiveRoles] = useState([])

  return (
    <div>
      {props.render === "representative" && (
        <RepresentativeContent
          props={props}
          setProps={setProps}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
        />
      )}
      {props.render === "memberList" && (
        <MembersListContent
          props={props}
          setProps={setProps}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          selectedCommittee={selectedCommittee}
          setSelectedCommittee={setSelectedCommittee}
          selectedPostPlace={selectedPostPlace}
          setSelectedPostPlace={setSelectedPostPlace}
          selectedPostCodes={selectedPostCodes}
          setSelectedPostCodes={setSelectedPostCodes}
          selectedDistrict={selectedDistrict}
          setSelectedDistrict={setSelectedDistrict}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          selectedActiveRoles={selectedActiveRoles}
          setSelectedActiveRoles={setSelectedActiveRoles}
          selectedInactiveRoles={selectedInactiveRoles}
          setSelectedInactiveRoles={setSelectedInactiveRoles}
        />
      )}
      {/* {props.render === "messageList" && (
        <MessageListContent props={props} setProps={setProps} />
      )} */}
      {props.render === "sendMessage" && (
        <SendMessageContent
          props={props}
          setProps={setProps}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
          selectedGender={selectedGender}
          setSelectedGender={setSelectedGender}
          selectedCommittee={selectedCommittee}
          setSelectedCommittee={setSelectedCommittee}
          selectedPostPlace={selectedPostPlace}
          setSelectedPostPlace={setSelectedPostPlace}
          selectedPostCodes={selectedPostCodes}
          setSelectedPostCodes={setSelectedPostCodes}
          selectedDistrict={selectedDistrict}
          setSelectedDistrict={setSelectedDistrict}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
          selectedActiveRoles={selectedActiveRoles}
          setSelectedActiveRoles={setSelectedActiveRoles}
          selectedInactiveRoles={selectedInactiveRoles}
          setSelectedInactiveRoles={setSelectedInactiveRoles}
        />
      )}
      {/* {props.render === "showMessage" && (
        <ShowMessageContent props={props} setProps={setProps} />
      )} */}
    </div>
  )
}

const RepresentativePage = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
    >
      <QueryClientProvider client={queryClient}>
        <RepresentativePageContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default RepresentativePage
