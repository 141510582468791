import React from "react"
import { chipButton, down } from "./Buttons"

const MessageChannelButtons = ({ isEmail, setIsEmail }) => (
  <div className="flex flex-wrap justify-center space-x-4">
    <button
      className={
        isEmail
          ? " flex justify-center items-center border-2 border-blue p-1 px-3 py-2 font-sans text-white bg-blue "
          : chipButton + down + " flex justify-center items-center "
      }
      onClick={() => {
        setIsEmail(true)
      }}
    >
      <span className="font-normal leading-none max-w-full flex-initial">
        E-post
      </span>
    </button>
    <button
      className={
        isEmail
          ? chipButton + down + " flex justify-center items-center "
          : " flex justify-center items-center border-2 border-blue p-1 px-3 py-2 font-sans text-white bg-blue "
      }
      onClick={() => {
        setIsEmail(false)
      }}
    >
      <span className="font-normal leading-none max-w-full flex-initial">
        SMS
      </span>
    </button>
  </div>
)

export default MessageChannelButtons
