import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter } from "../../Utils/Types"
import { XIcon } from "../../Graphics/XIcon"
import { EmptyCheckbox } from "../../Graphics/EmptyCheckbox"
import { ActiveCheckbox } from "../../Graphics/ActiveCheckbox"
import { DownArrow } from "../../Graphics/DownArrow"
import { Combobox } from "@headlessui/react"

export const PostCodesFilter = ({
  filterProps,
  setFilterProps,
  selectedPostCodes,
  setSelectedPostCodes,
  postCodes,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedPostCodes: any
  setSelectedPostCodes: Dispatch<SetStateAction<any>>
  postCodes: any
}) => {
  const [query, setQuery] = useState("")

  const filteredPostalCodes =
    query === ""
      ? postCodes
      : postCodes.filter(postCodes =>
          postCodes
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        )

  const removeFromList = value => {
    // console.log(value)
    setSelectedPostCodes(prevSelectedPostCodes =>
      prevSelectedPostCodes.filter(postCodes => postCodes !== value)
    )
  }

  useEffect(() => {
    const postCodes = selectedPostCodes?.map(postCodes => postCodes)
    setFilterProps({ ...filterProps, postCodes: postCodes?.join(",") })

    if (selectedPostCodes == "") {
      setFilterProps({ ...filterProps, postCodes: null })
    }
  }, [selectedPostCodes])

  return (
    <div className=" flex flex-col relative ">
      <Combobox
        value={selectedPostCodes}
        onChange={setSelectedPostCodes}
        multiple
      >
        <div className="flex">
          <Combobox.Input
            onChange={e => setQuery(e.target.value)}
            className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 w-full focus-visible:outline-none placeholder-black border-b-2 border-blue"
            placeholder="Søk etter postnummer"
          ></Combobox.Input>
          <Combobox.Button
            onClick={() => setQuery("")}
            className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 hover:opacity-80 border-b-2 border-blue"
          >
            <span>
              <DownArrow />
            </span>
          </Combobox.Button>
        </div>
        <Combobox.Options className=" h-auto overflow-y-scroll max-h-80 border-2 border-blue pt-4 bg-white absolute z-10 w-full top-12 ">
          {filteredPostalCodes?.map(postCodes => (
            <Combobox.Option
              className={({ active }) =>
                `flex text-blue px-4 hover:cursor-pointer ${
                  active ? "bg-gray-dark" : "bg-white"
                }`
              }
              key={postCodes}
              value={postCodes}
            >
              {({ selected }) => (
                <>
                  {selected ? (
                    <span className="mr-4">
                      <ActiveCheckbox />
                    </span>
                  ) : (
                    <span className="mr-4">
                      <EmptyCheckbox />
                    </span>
                  )}
                  {postCodes}
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
        <div className="mt-4">
          {selectedPostCodes?.map(postCodes => (
            <button
              onClick={() => removeFromList(postCodes)}
              className=" mr-2 mb-2 px-2 py-1 text-xs bg-blue text-white w-auto hover:opacity-80"
              key={postCodes}
              value={postCodes}
            >
              <div className="flex items-center">
                <span>{postCodes}</span>
                <span>
                  <XIcon />
                </span>
              </div>
            </button>
          ))}
        </div>
      </Combobox>
    </div>
  )
}
