import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Filter } from "../../Utils/Types"
import { Listbox, Transition } from "@headlessui/react"
import { PlusIcon } from "../../Graphics/PlusIcon"
import { XIcon } from "../../Graphics/XIcon"
import { EmptyCheckbox } from "../../Graphics/EmptyCheckbox"
import { ActiveCheckbox } from "../../Graphics/ActiveCheckbox"

const roles = [
  { name: "Kasserer / økonomiansvarlig" },
  { name: "Nestleder" },
  { name: "Varamedlem" },
  { name: "Leder" },
  { name: "Medlem" },
  { name: "Ungdomsrepresentant" },
]

export const ActiveRolesFilter = ({
  filterProps,
  setFilterProps,
  selectedActiveRoles,
  setSelectedActiveRoles,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedActiveRoles: any
  setSelectedActiveRoles: Dispatch<SetStateAction<any>>
}) => {
  const removeFromList = value => {
    console.log(value)
    setSelectedActiveRoles(prevSelectedRoles =>
      prevSelectedRoles.filter(roles => roles.name !== value)
    )
  }

  useEffect(() => {
    const names = selectedActiveRoles?.map(roles => roles.name)
    setFilterProps({
      ...filterProps,
      postRoles: names?.join(","),
      postStatus: 0,
    })

    if (selectedActiveRoles == "") {
      setFilterProps({ ...filterProps, postRoles: null, postStatus: null })
    }
  }, [selectedActiveRoles])

  return (
    <div className=" flex flex-col relative ">
      <Listbox
        value={selectedActiveRoles}
        onChange={setSelectedActiveRoles}
        multiple
      >
        <Listbox.Button className=" bg-gray px-4 py-2 mt-2 flex justify-between ml-0 mb-0 border-b-2 border-blue ">
          <span>Filtrer på nåværende verv</span>
          <span>
            <PlusIcon />
          </span>
        </Listbox.Button>
        <Listbox.Options className=" h-auto overflow-y-scroll max-h-80 border-2 border-blue pt-4 bg-white absolute z-10 w-full top-12 ">
          {roles?.map(roles => (
            <Listbox.Option
              className={({ active }) =>
                `flex text-blue px-4 hover:cursor-pointer ${
                  active ? "bg-gray-dark" : "bg-white"
                }`
              }
              key={roles?.name}
              value={roles}
            >
              {({ selected }) => (
                <>
                  {selected ? (
                    <span className="mr-4">
                      <ActiveCheckbox />
                    </span>
                  ) : (
                    <span className="mr-4">
                      <EmptyCheckbox />
                    </span>
                  )}
                  {roles?.name}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
        <div className="mt-4">
          {selectedActiveRoles?.map(roles => (
            <button
              onClick={() => removeFromList(roles.name)}
              className=" mr-2 mb-2 px-2 py-1 text-xs bg-blue text-white w-auto hover:opacity-80"
              key={roles?.name}
              value={roles?.name}
            >
              <div className="flex items-center">
                <span>{roles?.name}</span>
                <span>
                  <XIcon />
                </span>
              </div>
            </button>
          ))}
        </div>
      </Listbox>
    </div>
  )
}
