import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import { QueryClient, useMutation, useQuery } from "react-query"
import { GetProfile, GetQueries } from "../Utils/ReactQueries"
import ReactPaginate from "react-paginate"

import { useMsal } from "@azure/msal-react"
import { authToken } from "../Security/authToken"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import LoadingModal from "../Modal/LoadingModal"
import { right } from "../Styling/Buttons"
import { Filter, Paging, RepresentativeProps } from "../Utils/Types"
import { CommitteeSchema, MembershipsSchema } from "../Styling/QuerySchemas"
import { BackButton } from "../Styling/BackButton"
import { useForm } from "react-hook-form"
import { postApi } from "../Utils/WebApi-utils"
import { Filters } from "./Filter/Filters"
import { FilterSortButton } from "../Styling/FilterSortButton"

const queryClient = new QueryClient()

const MembersListContent = ({
  props,
  setProps,
  filterProps,
  setFilterProps,
  selectedGender,
  setSelectedGender,
  selectedCommittee,
  setSelectedCommittee,
  selectedPostPlace,
  setSelectedPostPlace,
  selectedPostCodes,
  setSelectedPostCodes,
  selectedDistrict,
  setSelectedDistrict,
  selectedBranch,
  setSelectedBranch,
  selectedActiveRoles,
  setSelectedActiveRoles,
  selectedInactiveRoles,
  setSelectedInactiveRoles,
}: {
  props: RepresentativeProps
  setProps: Dispatch<SetStateAction<RepresentativeProps>>
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedGender: any
  setSelectedGender: Dispatch<SetStateAction<any>>
  selectedCommittee: any
  setSelectedCommittee: Dispatch<SetStateAction<any>>
  selectedPostPlace: any
  setSelectedPostPlace: Dispatch<SetStateAction<any>>
  selectedPostCodes: any
  setSelectedPostCodes: Dispatch<SetStateAction<any>>
  selectedDistrict: any
  setSelectedDistrict: Dispatch<SetStateAction<any>>
  selectedBranch: any
  setSelectedBranch: Dispatch<SetStateAction<any>>
  selectedActiveRoles: any
  setSelectedActiveRoles: Dispatch<SetStateAction<any>>
  selectedInactiveRoles: any
  setSelectedInactiveRoles: Dispatch<SetStateAction<any>>
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [memberListEnabled, setMemberListEnabled] = useState(true)
  const [users, setUsers] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [loading, setLoading] = useState(false)
  // const [usersPerPage, setUsersPerPage] = useState(1)
  const [selectedMemberId, setSelectedMemberId] = useState("")
  const [filterCounter, setFilterCounter] = useState(0)
  const [filter, setFilter] = useState(false)
  const [pagingCookie, setPagingCookie] = useState<string>("")
  const [apiPagingNumber, setApiPagingNumber] = useState<number>(1)
  const [apiPaginationActive, setApiPaginationActive] = useState<boolean>(false)
  const [fetchXml, setFetchXml] = useState<string>("")
  const apiMaxCount = 100
  const usersPerPage = 25

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()

  const QueriesList = GetQueries(accounts, inProgress, instance)

  const GetHefPostalCode = (account, inProgress, instance) =>
    useQuery(
      "getPostalCode",
      async () => {
        setLoading(true)
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefPostalCode?postId=" +
              filterProps?.postId,
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: data => {
          setLoading(false)
        },
        // onError:() => {

        // },
      }
    )

  const PostalCodes = GetHefPostalCode(accounts, inProgress, instance)

  const districts = [
    ...new Set(PostalCodes?.data?.map(item => item.municipality)),
  ]

  const postPlaces = [...new Set(PostalCodes?.data?.map(item => item.city))]

  const postCodes = PostalCodes?.data?.map(item => {
    return item.postalcode
  })

  const GetCommitteeInfo = (account, inProgress, instance) =>
    useQuery(
      "committeeInfo",
      async () => {
        setLoading(true)
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefCommittee/contact?postId=" +
              filterProps?.postId +
              "&contactId=" +
              selectedMemberId +
              "&status=" +
              2,
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: 0,
        enabled: selectedMemberId !== "",
        onSuccess: data => {
          setLoading(false)
        },
        // onError:() => {

        // },
      }
    )

  const CommitteeInfo = GetCommitteeInfo(accounts, inProgress, instance)

  useEffect(() => {
    if (selectedMemberId !== "") {
      CommitteeInfo.refetch()
    }
  }, [selectedMemberId])

  const LocalsFromRegion = (account, inProgress, instance) =>
    useQuery(
      "localsFromRegion",
      async () => {
        setLoading(true)
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefBranch/LocalsFromRegion?postId=" +
              filterProps.postId,
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: data => {
          setLoading(false)
        },
        // onError:() => {

        // },
      }
    )

  const localBranches = LocalsFromRegion(accounts, inProgress, instance)

  const createFilter = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI +
          "HefQueries/Filter?queryId=" +
          props.queryId,
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setLoading(false)
        setUsers([])
        setPageNumber(0)
        if (data?.pagingCookie !== undefined) {
          setPagingCookie(data?.pagingCookie)
          setFetchXml(data?.fetchXml)
          setApiPaginationActive(true)
        } else {
          setApiPaginationActive(false)
          setUsers(data?.contacts)
        }
      },
      onError: data => {
        setLoading(false)
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoading(true)
          return false
        }
        return true
      },
    }
  )

  const createPaging = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefQueries/Paging",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setLoading(false)
      },
      onError: data => {
        setLoading(false)
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoading(true)
          return false
        }
        return true
      },
    }
  )

  useEffect(() => {
    if (
      fetchXml !== "" &&
      pagingCookie !== "" &&
      apiPaginationActive === true
    ) {
      onSubmitPaging()
    }
  }, [fetchXml, pagingCookie, apiPaginationActive])

  const onSubmitPaging = () => {
    const pagingObject: Paging = {
      fetchXml: fetchXml,
      pagingCookie: pagingCookie,
      number: apiPagingNumber.toString(),
    }
    createPaging.mutate(pagingObject)
  }

  useEffect(() => {
    setUsers(prevUsers => {
      const newContacts = apiPaginationActive
        ? createPaging?.data?.contacts ?? []
        : createFilter?.data?.contacts ?? []

      const filteredContacts = newContacts.filter(newContact => {
        return !prevUsers.some(prevUser => prevUser.id === newContact.id)
      })

      return [...prevUsers, ...filteredContacts]
    })
  }, [
    apiPaginationActive,
    createPaging?.data?.contacts,
    createFilter?.data?.contacts,
  ])

  const pagesVisited = pageNumber * usersPerPage

  const displayUsers = users
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((item, key) => {
      return (
        <div>
          {props.queryDescription.includes("$WebApi.Tillitsvalgte.01") && (
            <MembershipsSchema
              item={item}
              key={key}
              selectedMemberId={selectedMemberId}
              setSelectedMemberId={setSelectedMemberId}
              CommitteeInfo={CommitteeInfo}
            />
          )}
          {props.queryDescription.includes("$WebApi.Tillitsvalgte.02") && (
            <CommitteeSchema item={item} key={key} />
          )}
        </div>
      )
    })

  // Count amount of pages
  const pageCount = Math.ceil(createFilter?.data?.totalCount / usersPerPage)

  //Change page
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  useEffect(() => {
    const divident = apiMaxCount / usersPerPage
    const apiPage = (pageNumber + 1) / divident
    setApiPagingNumber(Math.ceil(apiPage))
  }, [pageNumber])

  useEffect(() => {
    if (apiPagingNumber > 1) {
      onSubmitPaging()
    }
  }, [apiPagingNumber])

  //Dynamic screenWidth for show filter on smaller screens
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension())

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension())
    }
    window.addEventListener("resize", updateDimension)

    return () => {
      window.removeEventListener("resize", updateDimension)
    }
  }, [screenWidth])

  useEffect(() => {
    const onloadObject: Filter = {
      postId: filterProps.postId,
      ageFrom: filterProps.ageFrom,
      ageTo: filterProps.ageTo,
      birthYearFrom: filterProps.birthYearFrom,
      birthYearTo: filterProps.birthYearTo,
      postCodes: filterProps.postCodes,
      postPlaces: filterProps.postPlaces,
      branch: filterProps.branch,
      district: filterProps.district,
      committteeTypes: filterProps.committteeTypes,
      postRoles: filterProps.postRoles,
      postStatus: filterProps.postStatus,
      memberFrom: filterProps.memberFrom,
      memberTo: filterProps.memberTo,
      genderCodes: filterProps.genderCodes,
      searchParameter: filterProps.searchParameter,
      orderField: filterProps.orderField,
      descending: filterProps.descending,
    }
    if (props.render === "memberList") {
      createFilter.mutate(onloadObject)
    }
  }, [props.render])

  //New prop values when the user goes to the previous page
  const prevPage = () => {
    setSelectedGender([])
    setSelectedCommittee([])
    setSelectedPostPlace([])
    setSelectedPostCodes([])
    setSelectedBranch([])
    setSelectedDistrict([])
    setSelectedActiveRoles([])
    setSelectedInactiveRoles([])
    setProps({
      ...props,
      render: "representative",
      queryId: "",
      queryDescription: "",
    })
    setFilterProps({
      ...filterProps,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
    })
    setMemberListEnabled(false)
  }

  //Submit filter
  const onSubmitFilter = () => {
    const filterObject: Filter = {
      postId: filterProps.postId,
      ageFrom: filterProps.ageFrom,
      ageTo: filterProps.ageTo,
      birthYearFrom:
        filterProps.birthYearFrom === null || filterProps.birthYearFrom < 999
          ? null
          : filterProps.birthYearFrom,
      birthYearTo:
        filterProps.birthYearTo === null || filterProps.birthYearTo < 999
          ? null
          : filterProps.birthYearTo,
      postCodes: filterProps.postCodes,
      postPlaces: filterProps.postPlaces,
      branch: filterProps.branch,
      district: filterProps.district,
      committteeTypes: filterProps.committteeTypes,
      postRoles: filterProps.postRoles,
      postStatus: filterProps.postStatus,
      memberFrom: filterProps.memberFrom,
      memberTo: filterProps.memberTo,
      genderCodes: filterProps.genderCodes,
      searchParameter: filterProps.searchParameter,
      orderField: filterProps.orderField,
      descending: filterProps.descending,
    }
    setLoading(true)
    createFilter.mutate(filterObject)
  }

  //Press enter to do a search with the input values
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      onSubmitFilter()
    }
  }

  //Submit each time a filterProp changes
  useEffect(() => {
    onSubmitFilter()
  }, [filterProps])

  //Reset filter
  const resetFilter = () => {
    setSelectedGender([])
    setSelectedCommittee([])
    setSelectedPostPlace([])
    setSelectedPostCodes([])
    setSelectedBranch([])
    setSelectedDistrict([])
    setSelectedActiveRoles([])
    setSelectedInactiveRoles([])
    setFilter(false)
    setFilterProps({
      ...filterProps,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
    })
    const resetObject: Filter = {
      postId: filterProps.postId,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
      orderField: filterProps.orderField,
      descending: filterProps.descending,
    }
    setLoading(true)
    createFilter.mutate(resetObject)
  }

  //If searchParameter goes back to "", convert the value to null
  useEffect(() => {
    if (filterProps?.searchParameter === "") {
      setFilterProps({ ...filterProps, searchParameter: null })
    }
  }, [filterProps?.searchParameter])

  //Add values to the filter counter. If the a value is not null add +1 on the filterCounter.
  useEffect(() => {
    const groupedProps = [
      ["ageFrom", "ageTo"],
      ["birthYearFrom", "birthYearTo"],
      ["memberFrom", "memberTo"],
      ["postCodes"],
      ["postPlaces"],
      ["branch"],
      ["district"],
      ["committteeTypes"],
      ["postRoles"],
      ["genderCodes"],
      ["searchParameter"],
    ]

    const counter = groupedProps.reduce((count, group) => {
      if (group.some(prop => filterProps[prop] !== null)) {
        return count + 1
      }
      return count
    }, 0)

    setFilterCounter(counter)
  }, [filterProps])

  console.log(filterProps)

  return (
    <div>
      {filter === true && screenWidth.width < 768 ? (
        <div
          className={
            " fixed z-100 inset-0 overflow-y-auto px-8 bg-white overflow-auto pt-4 mt-0 w-full font-sans"
          }
        >
          <form
            name="filter"
            method="post"
            onSubmit={handleSubmit(onSubmitFilter)}
          >
            <div className=" flex flex-row justify-between border-b-2 border-blue">
              {/*Remove filter*/}
              <button
                type="reset"
                onClick={resetFilter}
                className={
                  "border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t" +
                  right
                }
              >
                Fjern filter
              </button>
              <button
                type="button"
                className={
                  "border-2 bg-blue border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t" +
                  right
                }
                onClick={() => setFilter(false)}
              >
                Bruk filter
              </button>
            </div>
            <div className="mt-4 mb-4">
              <ul>
                <li>
                  <p className=" font-bold font-sans mb-0">
                    {createFilter?.data?.totalCount}{" "}
                    <span className="font-sans font-normal">
                      antall medlemmer
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <Filters
              props={props}
              filterProps={filterProps}
              setFilterProps={setFilterProps}
              selectedGender={selectedGender}
              setSelectedGender={setSelectedGender}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              localBranches={localBranches}
              selectedDistrict={selectedDistrict}
              setSelectedDistrict={setSelectedDistrict}
              districts={districts}
              selectedPostPlace={selectedPostPlace}
              setSelectedPostPlace={setSelectedPostPlace}
              postPlaces={postPlaces}
              selectedPostCodes={selectedPostCodes}
              setSelectedPostCodes={setSelectedPostCodes}
              postCodes={postCodes}
              selectedCommittee={selectedCommittee}
              setSelectedCommittee={setSelectedCommittee}
              selectedActiveRoles={selectedActiveRoles}
              setSelectedActiveRoles={setSelectedActiveRoles}
              selectedInactiveRoles={selectedInactiveRoles}
              setSelectedInactiveRoles={setSelectedInactiveRoles}
            />
          </form>
        </div>
      ) : (
        <div>
          {/* BackButton */}
          <BackButton onClick={prevPage} text={props?.name} />
          <div className="bg-gray px-8 lg:px-32 py-8 pb-16 ">
            <div className="">
              <div className="flex flex-col space-y-6">
                <h1 className="font-serif text-blue mb-4 text-xl font-normal">
                  Medlemmer
                </h1>
                <div>
                  <div className="flex flex-col">
                    <div className=" flex flex-col md:flex-row md:justify-between md:gap-8">
                      {/* Segment */}
                      <div className="flex flex-col justify-between md:basis-1/2 lg:basis-1/3 ">
                        <p className="text-sm font-sans mb-0">Velg segment</p>
                        <select
                          onChange={async e => {
                            let values = e.target.value.split(",")
                            let queryId = values[0]
                            let desc = values[1]
                            const selectedValue = e.target.value
                            setMemberListEnabled(true)
                            // setSearchParameter("")
                            await setProps({
                              ...props,
                              queryId: queryId,
                              queryDescription: desc,
                            })
                            onSubmitFilter()
                          }}
                          className="font-sans text-sm text-black border-b-2 bg-white border-blue p-2 mt-2 md:mt-0 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none"
                        >
                          {QueriesList?.data?.map(option => (
                            <option
                              key={option.id}
                              value={[option.id, option.description]}
                              selected={option.id === props.queryId}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* Search inputfield */}
                      <div className="relative flex justify-center items-center mt-8 md:basis-1/2 lg:basis-2/3">
                        <input
                          type="text"
                          className=" font-sans text-sm text-black border-b-2 border-blue p-2 pr-8 w-full h-12 placeholder-blue hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none "
                          placeholder="Søk"
                          onChange={e => {
                            setFilterProps({
                              ...filterProps,
                              searchParameter: e.target.value,
                            })
                          }}
                          value={
                            filterProps?.searchParameter === null
                              ? ""
                              : filterProps.searchParameter
                          }
                          onKeyDown={handleKeyPress}
                        />
                        <button
                          onClick={handleSubmit(onSubmitFilter)}
                          className="absolute right-0 mr-2"
                        >
                          <svg
                            className="text-blue hover:text-orange-dark"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M18.36 15.768C18.096 15.504 17.76 15.432 17.4 15.528C16.824 15.192 16.464 14.808 16.296 14.28C18.936 11.232 18.768 6.504 15.84 3.576C12.432 0.167999 7.176 0.311999 3.504 3.984C0.408 7.08 0.432 12.12 3.528 15.24C6.432 18.144 10.824 18.504 14.208 16.152C14.832 16.296 15.264 16.656 15.624 17.304C15.528 17.664 15.624 17.976 15.888 18.264L20.064 22.44C20.952 23.328 21.888 23.424 22.704 22.608C23.496 21.816 23.448 20.856 22.536 19.944L18.36 15.768ZM15.36 4.056C18.096 6.792 18.264 11.016 15.816 13.896L15.72 13.92C17.736 10.872 17.328 6.72 14.688 4.08C13.536 2.904 12.048 2.136 10.488 1.872C12.312 1.944 14.064 2.736 15.36 4.056ZM5.136 14.28C2.304 11.424 2.232 6.816 5.088 3.96C5.64 3.432 6.312 2.952 7.032 2.616C9.552 1.872 12.312 2.616 14.232 4.536C17.088 7.416 17.112 11.952 14.232 14.832C13.632 15.432 13.008 15.912 12.288 16.224C9.768 16.944 7.104 16.224 5.136 14.28ZM3.984 14.784C1.104 11.928 1.08 7.296 3.936 4.44L3.96 4.416C1.536 7.512 1.848 11.952 4.656 14.76C5.904 16.008 7.344 16.752 8.976 16.992C7.08 16.92 5.304 16.128 3.984 14.784ZM4.896 6.672C2.592 9.072 4.416 13.344 8.016 14.016C5.616 12.12 4.08 8.952 4.896 6.672ZM16.32 16.248C16.2 16.368 16.104 16.464 16.008 16.584C15.72 16.176 15.288 15.792 14.928 15.648C14.784 15.528 14.808 15.192 15.048 14.976C15.288 14.736 15.624 14.664 15.744 14.856C15.864 15.216 16.272 15.624 16.68 15.912C16.56 16.008 16.44 16.128 16.32 16.248ZM17.64 16.824C17.424 16.608 17.808 16.296 17.976 16.464L21.912 20.4C22.104 20.616 21.768 20.952 21.576 20.76L17.64 16.824Z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className=" flex flex-col md:flex-row md:mt-4 md:justify-between">
                      <div className="mt-2 flex flex-row justify-between">
                        {/* Filter button */}
                        <div className="flex flex-row gap-1 items-center">
                          <button
                            className=" text-blue font-sans mb-0 border-b-2 border-blue md:hidden "
                            onClick={() => setFilter(true)}
                          >
                            Filtrer
                          </button>
                          {filterCounter >= 1 ? (
                            <div className="w-[20px] h-[20px] bg-blue rounded-full flex flex-wrap justify-center items-center md:hidden">
                              <p className=" font-serif text-white text-xs mb-0">
                                {filterCounter}
                              </p>
                            </div>
                          ) : null}
                        </div>
                        {/* Counter */}
                        <ul>
                          <li>
                            <p className=" font-bold font-sans mb-0">
                              {createFilter?.data?.totalCount}{" "}
                              <span className="font-sans font-normal">
                                antall medlemmer
                              </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                      {/* New message button */}
                      <div className=" pt-8 md:pt-0 ">
                        <button
                          onClick={() => {
                            setProps({
                              ...props,
                              render: "sendMessage",
                              queryId: props.queryId,
                              queryDescription: "",
                            }),
                              setFilterProps({
                                ...filterProps,
                                orderField: null,
                                descending: false,
                              })
                          }}
                          className={
                            "border-2 bg-blue border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t" +
                            right
                          }
                        >
                          Send melding til filtrerte
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="pb-6 font-sans">
                    <div className=" inline pt-4 md:flex md:flex-row md:justify-between gap-8 ">
                      <div
                        className={
                          " hidden md:inline bg-white w-80 px-4 pt-4 mt-4 md:basis-1/2 lg:basis-1/3 "
                        }
                      >
                        <form
                          name="filter"
                          method="post"
                          onSubmit={handleSubmit(onSubmitFilter)}
                        >
                          <div className=" flex flex-row justify-between">
                            {/*Remove filter*/}
                            <button
                              type="reset"
                              onClick={resetFilter}
                              className={
                                "border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t" +
                                right
                              }
                            >
                              Fjern filter
                            </button>
                          </div>
                          <Filters
                            props={props}
                            filterProps={filterProps}
                            setFilterProps={setFilterProps}
                            selectedGender={selectedGender}
                            setSelectedGender={setSelectedGender}
                            selectedBranch={selectedBranch}
                            setSelectedBranch={setSelectedBranch}
                            localBranches={localBranches}
                            selectedDistrict={selectedDistrict}
                            setSelectedDistrict={setSelectedDistrict}
                            districts={districts}
                            selectedPostPlace={selectedPostPlace}
                            setSelectedPostPlace={setSelectedPostPlace}
                            postPlaces={postPlaces}
                            selectedPostCodes={selectedPostCodes}
                            setSelectedPostCodes={setSelectedPostCodes}
                            postCodes={postCodes}
                            selectedCommittee={selectedCommittee}
                            setSelectedCommittee={setSelectedCommittee}
                            selectedActiveRoles={selectedActiveRoles}
                            setSelectedActiveRoles={setSelectedActiveRoles}
                            selectedInactiveRoles={selectedInactiveRoles}
                            setSelectedInactiveRoles={setSelectedInactiveRoles}
                          />
                        </form>
                      </div>
                      {createPaging.isLoading ||
                      createFilter.isLoading ||
                      loading == true ? (
                        <LoadingModal />
                      ) : (
                        <div className="pt-4 md:basis-1/2 lg:basis-2/3">
                          {/* List of members */}
                          <div className=" flex flex-col mt-4 ">
                            <div className=" flex flex-row justify-evenly md:grid md:grid-cols-6 md:justify-between border-b-2 border-blue font-sans font-bold ">
                              <div className="md:grid md:col-span-2 justify-start">
                                <FilterSortButton
                                  props={filterProps}
                                  propValue={filterProps.orderField}
                                  setProps={setFilterProps}
                                  name={"Navn"}
                                  orderFieldValue={"fullname"}
                                  descendingValue={false}
                                />
                              </div>
                              <div className=" md:grid md:col-span-1 text-center justify-center">
                                <FilterSortButton
                                  props={filterProps}
                                  propValue={filterProps.orderField}
                                  setProps={setFilterProps}
                                  name={"Alder"}
                                  orderFieldValue={"birthdate"}
                                  descendingValue={false}
                                />
                              </div>
                              <div className="hidden md:grid md:col-span-2 text-center">
                                <p className=" mb-2 ">E-post</p>
                              </div>
                              <div className="hidden md:grid md:col-span-1">
                                <p className=" mb-2 ">Telefon</p>
                              </div>
                            </div>
                          </div>
                          <div>{displayUsers}</div>
                          {/* Paginate */}
                          {createFilter?.data?.contacts?.length > 0 ? (
                            <div className="mt-12 font-sans text-sm flex justify-center ">
                              <ReactPaginate
                                previousLabel={
                                  <div className="flex flex-row">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <label className="cursor-pointer">
                                      Forrige
                                    </label>
                                  </div>
                                }
                                nextLabel={
                                  <div className="flex flex-row">
                                    <label className="cursor-pointer">
                                      Neste
                                    </label>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-5 w-5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </div>
                                }
                                pageCount={pageCount}
                                onPageChange={changePage}
                                renderOnZeroPageCount={null}
                                containerClassName={"paginationButtons"}
                                previousLinkClassName={"prevButton"}
                                nextLinkClassName={"nextButton"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                                marginPagesDisplayed={0}
                                breakLabel={false}
                                pageRangeDisplayed={4}
                                forcePage={pageNumber}
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MembersListContent
