import React, { Dispatch, SetStateAction, useEffect } from "react"
import { inputField } from "../../Styling/InputField"
import { Filter } from "../../Utils/Types"

export const MembershipFilter = ({
  filterProps,
  setFilterProps,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
}) => {
  useEffect(() => {
    if (Number.isNaN(filterProps.memberFrom)) {
      setFilterProps({ ...filterProps, memberFrom: null })
    }

    if (Number.isNaN(filterProps.memberTo)) {
      setFilterProps({ ...filterProps, memberTo: null })
    }
  }, [filterProps?.memberFrom, filterProps?.memberTo])

  return (
    <div className="mb-4">
      <p className="mb-1">Har vært medlem</p>
      <div className=" flex flex-row space-x-2 flex-wrap ">
        <p className="self-center mb-0">Fra</p>
        <input
          type="number"
          placeholder="0"
          defaultValue={
            filterProps?.memberFrom === null ? "" : filterProps?.memberFrom
          }
          onInput={e => {
            setFilterProps({
              ...filterProps,
              memberFrom: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-12 lg:w-16 text-center"
          }
        ></input>
        <p className="self-center mb-0">år</p>
        <p className="self-center mb-0">til</p>
        <input
          type="number"
          placeholder="150"
          defaultValue={
            filterProps?.memberTo === null ? "" : filterProps?.memberTo
          }
          onInput={e => {
            setFilterProps({
              ...filterProps,
              memberTo: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-12 lg:w-16 text-center"
          }
        ></input>
        <p className="self-center mb-0">år</p>
      </div>
    </div>
  )
}
