import React, { Dispatch, SetStateAction, useEffect } from "react"
import { inputField } from "../../Styling/InputField"
import { Filter } from "../../Utils/Types"

export const AgeFilter = ({
  filterProps,
  setFilterProps,
}: {
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
}) => {
  useEffect(() => {
    if (Number.isNaN(filterProps?.ageFrom)) {
      setFilterProps({ ...filterProps, ageFrom: null })
    }

    if (Number.isNaN(filterProps?.ageTo)) {
      setFilterProps({ ...filterProps, ageTo: null })
    }
  }, [filterProps?.ageFrom, filterProps?.ageTo])

  return (
    <div className="pt-4 ">
      <p className="mb-1">Alder</p>
      <div className=" flex flex-row space-x-2 flex-wrap ">
        <p className="self-center mb-0">Fra</p>
        <input
          type="number"
          placeholder="0"
          defaultValue={
            filterProps?.ageFrom === null ? "" : filterProps?.ageFrom
          }
          onInput={e => {
            setFilterProps({
              ...filterProps,
              ageFrom: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-12 lg:w-16 text-center"
          }
        ></input>
        <p className="self-center mb-0">år</p>
        <p className="self-center mb-0">til</p>
        <input
          placeholder="150"
          type="number"
          defaultValue={filterProps?.ageTo === null ? "" : filterProps?.ageTo}
          onInput={e => {
            setFilterProps({
              ...filterProps,
              ageTo: parseInt((e.target as HTMLInputElement).value),
            })
          }}
          className={
            " font-sans text-sm text-black border-b-2 border-blue p-2 h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none mt-0 bg-gray w-12 lg:w-16 text-center"
          }
        ></input>
        <p className="self-center mb-0">år</p>
      </div>
    </div>
  )
}
