import React from "react"

export const ActiveCheckbox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      fill="#004C97"
      stroke="#004C97"
      stroke-width="2"
    />
    <path
      d="M13.02 18L17.898 5.076H16.134L12.138 15.84L8.142 5.076H6.288L11.202 18H13.02Z"
      fill="white"
    />
  </svg>
)
