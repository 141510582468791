import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query"
import { Gender, GetProfile, GetQueries } from "../Utils/ReactQueries"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
import "react-toastify/dist/ReactToastify.css"
import {
  chipButton,
  down,
  right,
  saveButton,
  underlineButton,
} from "../Styling/Buttons"
import { useForm } from "react-hook-form"
import axios from "axios"
import {
  inputField,
  inputFieldError,
  inputFieldErrorMessage,
  selectValue,
  selectValueError,
  smsInputField,
} from "../Styling/InputField"
import { Filter, RepresentativeProps } from "../Utils/Types"
import { count } from "sms-length"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { authToken } from "../Security/authToken"
import { postApi } from "../Utils/WebApi-utils"
import { toast, ToastContainer } from "react-toastify"
import LoadingModal from "../Modal/LoadingModal"
import ConfirmMessageModal from "../Modal/ConfirmMessageModal"
import { Filters } from "./Filter/Filters"
import MessageChannelButtons from "../Styling/MessageChannelButtons"

const queryClient = new QueryClient()

const SendMessageContent = ({
  props,
  setProps,
  filterProps,
  setFilterProps,
  selectedGender,
  setSelectedGender,
  selectedCommittee,
  setSelectedCommittee,
  selectedPostPlace,
  setSelectedPostPlace,
  selectedPostCodes,
  setSelectedPostCodes,
  selectedDistrict,
  setSelectedDistrict,
  selectedBranch,
  setSelectedBranch,
  selectedActiveRoles,
  setSelectedActiveRoles,
  selectedInactiveRoles,
  setSelectedInactiveRoles,
}: {
  props: RepresentativeProps
  setProps: Dispatch<SetStateAction<RepresentativeProps>>
  filterProps: Filter
  setFilterProps: Dispatch<SetStateAction<Filter>>
  selectedGender: any
  setSelectedGender: Dispatch<SetStateAction<any>>
  selectedCommittee: any
  setSelectedCommittee: Dispatch<SetStateAction<any>>
  selectedPostPlace: any
  setSelectedPostPlace: Dispatch<SetStateAction<any>>
  selectedPostCodes: any
  setSelectedPostCodes: Dispatch<SetStateAction<any>>
  selectedDistrict: any
  setSelectedDistrict: Dispatch<SetStateAction<any>>
  selectedBranch: any
  setSelectedBranch: Dispatch<SetStateAction<any>>
  selectedActiveRoles: any
  setSelectedActiveRoles: Dispatch<SetStateAction<any>>
  selectedInactiveRoles: any
  setSelectedInactiveRoles: Dispatch<SetStateAction<any>>
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [characterCounter, setCharacterCounter] = useState(0)
  const [isEmail, setIsEmail] = useState(true)
  const [smsString, setSmsString] = useState("")
  const [emailString, setEmailString] = useState("")
  const [subjectString, setSubjectString] = useState("")
  const [submitSmsType, setSubmitSmsType] = useState("")
  const [submitEmailType, setSubmitEmailType] = useState("")
  const [testSmsWasSent, setTestSmsWasSent] = useState(false)
  const [testEmailWasSent, setTestEmailWasSent] = useState(false)
  const [memberListEnabled, setMemberListEnabled] = useState(false)
  const [filter, setFilter] = useState(false)
  const [filterCounter, setFilterCounter] = useState(0)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors },
  } = useForm()
  const [errorMessageSMS, setErrorMessageSMS] = useState("")
  const [errorMessageEmail, setErrorMessageEmail] = useState("")
  const [testMessage, setTestMessage] = useState(false)

  const modules = {
    toolbar: [["link"]],
  }

  const handlebody = e => {
    console.log(e), setEmailString(e)
  }
  const ReactQuill =
    typeof window === "object" ? require("react-quill") : () => false

  //The link button is now "Legg til link"
  const icons = ReactQuill.Quill.import("ui/icons")
  icons["link"] = "<label>Legg til lenke</label>"

  //If the user doesnt include a https:// or http:// at the start of a link, this code will automatically insert it when they press save.
  const Link = ReactQuill.Quill.import("formats/link")
  Link.sanitize = function (url) {
    // quill by default creates relative links if scheme is missing.
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`
    }
    return url
  }

  const myProfile = GetProfile(accounts, inProgress, instance)

  const QueriesList = GetQueries(accounts, inProgress, instance)

  const [screenWidth, setScreenWidth] = useState(getCurrentDimension())

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension())
    }
    window.addEventListener("resize", updateDimension)

    return () => {
      window.removeEventListener("resize", updateDimension)
    }
  }, [screenWidth])

  const GetHefPostalCode = (account, inProgress, instance) =>
    useQuery(
      "getPostalCode",
      async () => {
        setLoading(true)
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefPostalCode?postId=" +
              filterProps.postId,
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: data => {
          setLoading(false)
        },
        // onError:() => {

        // },
      }
    )

  const PostalCodes = GetHefPostalCode(accounts, inProgress, instance)

  const districts = [
    ...new Set(PostalCodes?.data?.map(item => item.municipality)),
  ]

  const postPlaces = [...new Set(PostalCodes?.data?.map(item => item.city))]

  const postCodes = PostalCodes?.data?.map(item => {
    return item.postalcode
  })

  const LocalsFromRegion = (account, inProgress, instance) =>
    useQuery(
      "localsFromRegion",
      async () => {
        setLoading(true)
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefBranch/LocalsFromRegion?postId=" +
              filterProps.postId,
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: 0,
        onSuccess: data => {
          setLoading(false)
        },
        // onError:() => {

        // },
      }
    )

  const localBranches = LocalsFromRegion(accounts, inProgress, instance)

  const createFilter = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI +
          "HefQueries/Filter?queryId=" +
          props.queryId +
          "&searchParameter=" +
          "",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setLoading(false)
      },
      onError: data => {
        setLoading(false)
      },
      retry: (count, error) => {
        if (count >= 1) {
          setLoading(true)
          return false
        }
        return true
      },
    }
  )

  useEffect(() => {
    const onloadObject: Filter = {
      postId: filterProps.postId,
      ageFrom: filterProps.ageFrom,
      ageTo: filterProps.ageTo,
      birthYearFrom: filterProps.birthYearFrom,
      birthYearTo: filterProps.birthYearTo,
      postCodes: filterProps.postCodes,
      postPlaces: filterProps.postPlaces,
      branch: filterProps.branch,
      district: filterProps.district,
      committteeTypes: filterProps.committteeTypes,
      postRoles: filterProps.postRoles,
      postStatus: filterProps.postStatus,
      memberFrom: filterProps.memberFrom,
      memberTo: filterProps.memberTo,
      genderCodes: filterProps.genderCodes,
      searchParameter: filterProps.searchParameter,
    }
    if (props.render === "sendMessage" && props.queryId !== "") {
      createFilter.mutate(onloadObject)
    }
  }, [props.render])

  //Submit filter
  const onSubmitFilter = () => {
    const filterObject: Filter = {
      postId: filterProps.postId,
      ageFrom: filterProps.ageFrom,
      ageTo: filterProps.ageTo,
      birthYearFrom:
        filterProps.birthYearFrom === null || filterProps.birthYearFrom < 999
          ? null
          : filterProps.birthYearFrom,
      birthYearTo:
        filterProps.birthYearTo === null || filterProps.birthYearTo < 999
          ? null
          : filterProps.birthYearTo,
      postCodes: filterProps.postCodes,
      postPlaces: filterProps.postPlaces,
      branch: filterProps.branch,
      district: filterProps.district,
      committteeTypes: filterProps.committteeTypes,
      postRoles: filterProps.postRoles,
      postStatus: filterProps.postStatus,
      memberFrom: filterProps.memberFrom,
      memberTo: filterProps.memberTo,
      genderCodes: filterProps.genderCodes,
      searchParameter: filterProps.searchParameter,
    }
    setLoading(true)
    createFilter.mutate(filterObject)
  }

  //Submit each time a filterProp changes
  useEffect(() => {
    if (props.queryId !== "") {
      onSubmitFilter()
    }
  }, [filterProps])

  //Reset filter
  const resetFilter = () => {
    setSelectedGender([])
    setSelectedCommittee([])
    setSelectedPostPlace([])
    setSelectedPostCodes([])
    setSelectedBranch([])
    setSelectedDistrict([])
    setSelectedActiveRoles([])
    setSelectedInactiveRoles([])
    setFilter(false)
    setFilterProps({
      ...filterProps,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
    })
    const resetObject: Filter = {
      postId: filterProps.postId,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
    }
    setLoading(true)
    createFilter.mutate(resetObject)
  }

  //New prop values when the user goes to the previous page
  const prevPage = () => {
    setSelectedGender([])
    setSelectedCommittee([])
    setSelectedPostPlace([])
    setSelectedPostCodes([])
    setSelectedBranch([])
    setSelectedDistrict([])
    setSelectedActiveRoles([])
    setSelectedInactiveRoles([])
    setProps({
      ...props,
      render: "representative",
      queryId: "",
      queryDescription: "",
    })
    setFilterProps({
      ...filterProps,
      ageFrom: null,
      ageTo: null,
      birthYearFrom: null,
      birthYearTo: null,
      postCodes: null,
      postPlaces: null,
      branch: null,
      district: null,
      committteeTypes: null,
      postRoles: null,
      postStatus: null,
      memberFrom: null,
      memberTo: null,
      genderCodes: null,
      searchParameter: null,
    })
    setMemberListEnabled(false)
  }

  const PostMessage = useMutation(
    async values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefQueries/RepresentativeSendOff",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        {
          toast.success("Meldingen ble sendt", {
            bodyClassName: "bg-blue text-white",
            theme: "colored",
            style: { backgroundColor: "#004C97" },
          }),
            queryClient.invalidateQueries(["getQueries"]),
            console.log(data)
        }
      },
      onError: data => {
        setLoading(false)
        toast.error("Noe gikk galt med test meldingen!")
      },
    }
  )

  let smsTestObject: any = {
    localBranchId: props.branchId,
    test: true,
    description: smsString,
    subject: "sms",
    userQuery: "Test",
    fetchXml: createFilter?.data?.fetchXml,
    channel: "sms",
  }

  const onSubmitSms = values => {
    // Send test SMS without a message.
    if (submitSmsType == "testSms" && smsString == "") {
      setErrorMessageSMS("Du har glemt å fylle inn noen felter.")
    }
    // Send test SMS without receiver.
    else if (submitSmsType == "testSms" && props.queryId == "") {
      setErrorMessageSMS(
        "For å sende en test-melding til deg selv, må du velge mottaker først. Test-meldingen vil kun sendes ut til deg."
      )
    }
    //Send SMS without sending a test SMS first.
    else if (submitSmsType == "sendSms" && testSmsWasSent == false) {
      setErrorMessageSMS("Husk å sende test-SMS først.")
    }
    // Send SMS without a segment or message.
    else if (
      submitSmsType == "sendSms" &&
      testSmsWasSent == true &&
      (props.queryId == "" || smsString == "")
    ) {
      setErrorMessageSMS("Du har glemt å fylle inn noen felter.")
    }
    // Open modal for sms
    else if (submitSmsType == "sendSms" && testSmsWasSent == true) {
      setOpenModal(true)
    }
    // Send test SMS
    else if (submitSmsType == "testSms") {
      setTestSmsWasSent(true)
      setErrorMessageSMS("")
      PostMessage.mutate(smsTestObject)
    }
  }

  let emailTestObject: any = {
    localBranchId: props.branchId,
    test: true,
    description: emailString,
    subject: subjectString,
    userQuery: "Test",
    fetchXml: createFilter?.data?.fetchXml,
    channel: "email",
  }

  const onSubmitEmail = values => {
    //Send a test without a subject og message.
    if (
      submitEmailType == "testEmail" &&
      (subjectString == "" ||
        //Needs the <p><br></p> rule because that is the default value once you add a character and removes it in the text editor.
        emailString == "<p><br></p>" ||
        emailString == "")
    ) {
      setErrorMessageEmail("Du har glemt å fylle inn noen felter.")
    }
    //Send test Email without receiver.
    else if (submitEmailType == "testEmail" && props.queryId == "") {
      setErrorMessageEmail(
        "For å sende en test-melding til deg selv, må du velge mottaker først. Test-meldingen vil kun sendes ut til deg."
      )
    }
    //Send an email without sending a test message first.
    else if (submitEmailType == "sendEmail" && testEmailWasSent == false) {
      setErrorMessageEmail("Husk å sende test-epost først.")
    }
    // Test email is already sent but there are values missing in segment, message or subject.
    else if (
      submitEmailType == "sendEmail" &&
      testEmailWasSent == true &&
      (props.queryId == "" ||
        subjectString == "" ||
        emailString == "" ||
        emailString == "<p><br></p>")
    ) {
      // setShowRequiredErrorEmail(true)
      setErrorMessageEmail("Du har glemt å fylle inn noen felter.")
    }
    // Open modal to send real sms
    else if (submitEmailType == "sendEmail" && testEmailWasSent == true) {
      setOpenModal(true)
    }
    // Send test email
    else if (submitEmailType == "testEmail") {
      setTestEmailWasSent(true)
      setErrorMessageEmail("")
      PostMessage.mutate(emailTestObject)
    }
  }

  //Sms-length values from the string
  const response = count(smsString)

  console.log(filterProps)

  //Add values to the filter counter. If the a value is not null add +1 on the filterCounter.
  useEffect(() => {
    let counter = 0
    if (filterProps.ageFrom !== null || filterProps.ageTo !== null) {
      counter += 1
    }
    if (
      filterProps.birthYearFrom !== null ||
      filterProps.birthYearTo !== null
    ) {
      counter += 1
    }
    if (filterProps.postCodes !== null) {
      counter += 1
    }
    if (filterProps.postPlaces !== null) {
      counter += 1
    }
    if (filterProps.branch !== null) {
      counter += 1
    }
    if (filterProps.district !== null) {
      counter += 1
    }
    if (filterProps.committteeTypes !== null) {
      counter += 1
    }
    if (filterProps.postRoles !== null) {
      counter += 1
    }
    if (filterProps.memberFrom !== null || filterProps.memberTo !== null) {
      counter += 1
    }

    if (filterProps.genderCodes !== null) {
      counter += 1
    }

    if (filterProps.searchParameter !== null) {
      counter += 1
    }

    setFilterCounter(counter)
  }, [filterProps])

  return (
    <div>
      {openModal ? (
        <ConfirmMessageModal
          props={props}
          setProps={setProps}
          filterProps={filterProps}
          setFilterProps={setFilterProps}
          openModal={openModal}
          setOpenModal={setOpenModal}
          amount={createFilter?.data?.contacts?.length}
          isEmail={isEmail}
          price={Math.ceil(
            (createFilter?.data?.totalCount - createFilter?.data?.noSmsCount) *
              response.messages *
              0.78
          )}
          totalCount={createFilter?.data?.totalCount}
          noSmsCount={createFilter?.data?.noSmsCount}
          noEmailCount={createFilter?.data?.noEmailCount}
          smsString={smsString}
          emailString={emailString}
          subjectString={subjectString}
          fetchXml={createFilter?.data?.fetchXml}
          setSelectedGender={setSelectedGender}
          setSelectedCommittee={setSelectedCommittee}
          setSelectedPostPlace={setSelectedPostPlace}
          setSelectedPostCodes={setSelectedPostCodes}
          setSelectedBranch={setSelectedBranch}
          setSelectedDistrict={setSelectedDistrict}
          setSelectedActiveRoles={setSelectedActiveRoles}
          setSelectedInactiveRoles={setSelectedInactiveRoles}
        />
      ) : null}
      {filter === true && screenWidth.width < 768 ? (
        <div
          className={
            " fixed z-100 inset-0 overflow-y-auto px-8 bg-white overflow-auto pt-4 mt-0 w-full font-sans"
          }
        >
          <form
            name="filter"
            method="post"
            onSubmit={handleSubmit(onSubmitFilter)}
          >
            <div className=" flex flex-row justify-between border-b-2 border-blue">
              {/*Remove filter*/}
              <button
                type="reset"
                onClick={resetFilter}
                className={
                  "border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t" +
                  right
                }
              >
                Fjern filter
              </button>
              <button
                type="button"
                className={
                  "border-2 bg-blue border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-white hover:text-blue hover:bg-100 transition-all duration-100 bg-no-repeat to-white from-white bg-gradient-to-t" +
                  right
                }
                onClick={() => setFilter(false)}
              >
                Bruk filter
              </button>
            </div>
            <div className="mt-4 mb-4">
              <p className=" font-bold font-sans mb-0">
                {createFilter?.data?.totalCount}{" "}
                <span className="font-sans font-normal">treff</span>
              </p>
            </div>
            <Filters
              props={props}
              filterProps={filterProps}
              setFilterProps={setFilterProps}
              selectedGender={selectedGender}
              setSelectedGender={setSelectedGender}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              localBranches={localBranches}
              selectedDistrict={selectedDistrict}
              setSelectedDistrict={setSelectedDistrict}
              districts={districts}
              selectedPostPlace={selectedPostPlace}
              setSelectedPostPlace={setSelectedPostPlace}
              postPlaces={postPlaces}
              selectedPostCodes={selectedPostCodes}
              setSelectedPostCodes={setSelectedPostCodes}
              postCodes={postCodes}
              selectedCommittee={selectedCommittee}
              setSelectedCommittee={setSelectedCommittee}
              selectedActiveRoles={selectedActiveRoles}
              setSelectedActiveRoles={setSelectedActiveRoles}
              selectedInactiveRoles={selectedInactiveRoles}
              setSelectedInactiveRoles={setSelectedInactiveRoles}
            />
          </form>
        </div>
      ) : (
        <div>
          <div className="px-8 lg:px-32">
            <div className=" border-b-2 border-blue pt-2 mb-0">
              <button
                onClick={prevPage}
                className="flex flex-row items-center font-sans text-blue text-sm mb-2 hover:text-orange-dark hover:cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                {props?.name}
              </button>
            </div>
          </div>
          <div className="bg-gray px-8 lg:px-32 py-8 pb-16">
            <div>
              <div className="flex flex-col space-y-6">
                <h1 className="font-serif text-blue mb-4 text-xl font-normal">
                  Send melding
                </h1>
                <div className=" pb-6 font-sans">
                  <div className="flex flex-col">
                    <div className=" inline pt-4 md:flex md:flex-row md:justify-between gap-8 ">
                      {props.queryId === "" ? null : (
                        <div
                          className={
                            " hidden md:inline bg-white w-80 px-4 pt-4 mt-4 md:basis-1/2 lg:basis-1/3 "
                          }
                        >
                          <form
                            name="filter"
                            method="post"
                            onSubmit={handleSubmit(onSubmitFilter)}
                          >
                            <div className=" flex flex-row justify-between">
                              {/*Remove filter*/}
                              <button
                                type="reset"
                                onClick={resetFilter}
                                className={
                                  "border-2 border-blue p-1 px-3 pt-1 pb-2 mr-8 md:mr-0 mb-4 font-sans text-blue hover:text-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t" +
                                  right
                                }
                              >
                                Fjern filter
                              </button>
                            </div>
                            <Filters
                              props={props}
                              filterProps={filterProps}
                              setFilterProps={setFilterProps}
                              selectedGender={selectedGender}
                              setSelectedGender={setSelectedGender}
                              selectedBranch={selectedBranch}
                              setSelectedBranch={setSelectedBranch}
                              localBranches={localBranches}
                              selectedDistrict={selectedDistrict}
                              setSelectedDistrict={setSelectedDistrict}
                              districts={districts}
                              selectedPostPlace={selectedPostPlace}
                              setSelectedPostPlace={setSelectedPostPlace}
                              postPlaces={postPlaces}
                              selectedPostCodes={selectedPostCodes}
                              setSelectedPostCodes={setSelectedPostCodes}
                              postCodes={postCodes}
                              selectedCommittee={selectedCommittee}
                              setSelectedCommittee={setSelectedCommittee}
                              selectedActiveRoles={selectedActiveRoles}
                              setSelectedActiveRoles={setSelectedActiveRoles}
                              selectedInactiveRoles={selectedInactiveRoles}
                              setSelectedInactiveRoles={
                                setSelectedInactiveRoles
                              }
                            />
                          </form>
                        </div>
                      )}
                      <div className=" basis-2/3 flex flex-col mb-8">
                        <div className=" border-b-2 border-blue mb-4">
                          <div className="flex flex-col space-y-4 mb-8 ">
                            <label className="font-sans text-blue">
                              Velg kanal
                            </label>
                            <div className="flex flex-row">
                              <MessageChannelButtons
                                isEmail={isEmail}
                                setIsEmail={setIsEmail}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div>
                            <label className="font-sans text-blue">
                              Mottakere
                            </label>
                          </div>
                          <div>
                            <select
                              onChange={async e => {
                                // const selectedValue = e.target.value
                                // setProps({ ...props, queryId: selectedValue })
                                // MembersList.refetch()
                                let queryId = e.target.value
                                await setProps({ ...props, queryId: queryId })
                                setMemberListEnabled(true)
                                onSubmitFilter()
                              }}
                              className={
                                props.queryId == "" &&
                                (errorMessageEmail != "" ||
                                  errorMessageSMS != "")
                                  ? selectValueError
                                  : selectValue
                              }
                            >
                              <option
                                key="0"
                                value=""
                                style={{ display: "none" }}
                                selected
                              >
                                Velg et segment
                              </option>
                              {QueriesList?.data?.map(option => (
                                <option
                                  key={option.id}
                                  value={[option.id]}
                                  selected={
                                    props.queryId != ""
                                      ? option.id === props.queryId
                                      : false
                                  }
                                >
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {filterProps.searchParameter !== null ? (
                            <div>
                              <input
                                disabled
                                className={inputField + " mt-2 bg-white "}
                                value={filterProps?.searchParameter}
                              />
                            </div>
                          ) : null}
                          {loading == true ? (
                            <LoadingModal />
                          ) : (
                            <div>
                              {props.queryId == "" ? null : (
                                <div className="mt-2 flex flex-row justify-between">
                                  {/* Filter button */}
                                  <div className="flex flex-row gap-1 items-center md:hidden">
                                    <button
                                      className=" text-blue font-sans mb-0 border-b-2 border-blue md:hidden "
                                      onClick={() => setFilter(true)}
                                    >
                                      Filtrer
                                    </button>
                                    {filterCounter >= 1 ? (
                                      <div className="w-[20px] h-[20px] bg-blue rounded-full flex flex-wrap justify-center items-center md:hidden">
                                        <p className=" font-serif text-white text-xs mb-0">
                                          {filterCounter}
                                        </p>
                                      </div>
                                    ) : null}
                                  </div>
                                  {/* Counter */}
                                  <div>
                                    <p className=" font-bold font-sans mb-0">
                                      {createFilter?.data?.totalCount}{" "}
                                      <span className="font-sans font-normal">
                                        treff
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              )}
                              {isEmail ? (
                                <form
                                  name="emailMessage"
                                  method="post"
                                  onSubmit={handleSubmit(onSubmitEmail)}
                                >
                                  <div className=" mt-6 ">
                                    <label className="font-sans text-blue">
                                      Emne
                                    </label>
                                    <input
                                      value={subjectString}
                                      onChange={e => {
                                        setSubjectString(e.target.value)
                                      }}
                                      className={
                                        subjectString == "" &&
                                        errorMessageEmail != ""
                                          ? inputFieldErrorMessage
                                          : inputField
                                      }
                                    ></input>
                                  </div>
                                  <div className=" mt-6 ">
                                    <div className="mb-2">
                                      <label className="font-sans text-blue">
                                        Melding
                                      </label>
                                    </div>
                                    <div>
                                      <div>
                                        <ReactQuill
                                          theme="snow"
                                          value={emailString}
                                          modules={modules}
                                          style={{ fontSize: "16px" }}
                                          onChange={handlebody}
                                          className={
                                            (emailString == "" ||
                                              emailString == "<p><br></p>") &&
                                            errorMessageEmail != ""
                                              ? "border-2 border-orange-dark"
                                              : "border-b-2 border-blue focus:ring-2 focus:ring-blue focus:border-0"
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      className={saveButton + down + " mt-8 "}
                                      onClick={e => {
                                        setSubmitEmailType("sendEmail")
                                      }}
                                      type="submit"
                                    >
                                      Send nå
                                    </button>
                                    <button
                                      className={underlineButton}
                                      onClick={e => {
                                        setSubmitEmailType("testEmail")
                                      }}
                                      type="submit"
                                    >
                                      Test til {myProfile?.data?.email}
                                    </button>
                                  </div>
                                  {errorMessageEmail !== "" ? (
                                    <div className="font-sans text-orange-dark">
                                      <p className="mb-0">
                                        {errorMessageEmail}
                                      </p>
                                    </div>
                                  ) : null}
                                </form>
                              ) : (
                                <form
                                  name="smsMessage"
                                  method="post"
                                  onSubmit={handleSubmit(onSubmitSms)}
                                >
                                  <div className=" mt-4 ">
                                    <label className="font-sans text-blue">
                                      Avsendernavn
                                    </label>
                                  </div>
                                  <div className=" mt-2 ">
                                    <p className="font-sans mb-0">Human.no</p>
                                  </div>
                                  <div className=" mt-6 mb-2">
                                    <label className="font-sans text-blue">
                                      Melding
                                    </label>
                                  </div>
                                  <div
                                    className={
                                      smsString == "" && errorMessageSMS != ""
                                        ? "border-2 border-orange-dark"
                                        : "border-b-2 border-t-2 border-x-2 border-white border-b-blue hover:border-2 hover:border-blue"
                                    }
                                  >
                                    <div className="bg-white">
                                      <textarea
                                        className={smsInputField}
                                        value={smsString}
                                        onChange={e => {
                                          setSmsString(e.target.value)
                                          setCharacterCounter(
                                            e.target.value.length
                                          )
                                        }}
                                      ></textarea>
                                    </div>
                                    <div className="p-2 bg-white">
                                      <label className="font-sans text-blue">
                                        {response.length}/
                                        {response.characterPerMessage} (
                                        {response.messages} SMS-er)
                                      </label>
                                    </div>
                                  </div>
                                  {props.queryId == "" ? null : (
                                    <div className="flex flex-col mt-2 font-sans">
                                      <p className="mb-0">
                                        Hele utsendelsen koster cirka{" "}
                                        {Math.ceil(
                                          (createFilter?.data?.totalCount -
                                            createFilter?.data?.noSmsCount) *
                                            response.messages *
                                            0.78
                                        )}{" "}
                                        kr
                                      </p>
                                    </div>
                                  )}
                                  <div>
                                    <button
                                      className={saveButton + down + " mt-8 "}
                                      onClick={e => {
                                        setSubmitSmsType("sendSms")
                                      }}
                                      type="submit"
                                    >
                                      Send nå
                                    </button>
                                    <button
                                      className={underlineButton}
                                      onClick={e => {
                                        setSubmitSmsType("testSms")
                                      }}
                                      type="submit"
                                    >
                                      Test til {myProfile?.data?.mobilePhone}
                                    </button>
                                  </div>
                                  {errorMessageSMS !== "" ? (
                                    <div className="font-sans text-orange-dark">
                                      <p className="mb-0">{errorMessageSMS}</p>
                                    </div>
                                  ) : null}
                                </form>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SendMessageContent
