import moment from "moment"
import "moment/locale/nb"
import React, { Component } from "react"
import { DownArrow } from "../Graphics/DownArrow"
import { UpArrow } from "../Graphics/UpArrow"

moment.locale("nb")

export const MembershipsSchema = ({
  item,
  key,
  selectedMemberId,
  setSelectedMemberId,
  CommitteeInfo,
}) => {
  const openSelectedMember = () => {
    if (item?.numberOfPost > 0 && selectedMemberId !== item?.id) {
      setSelectedMemberId(item?.id)
    } else if (item?.numberOfPost > 0 && selectedMemberId === item?.id) {
      setSelectedMemberId("")
    }
  }

  CommitteeInfo?.data?.sort((a, b) => {
    if (a.statusCode !== b.statusCode) {
      return a.statusCode - b.statusCode
    }

    const dateA = new Date(a.fromDate)
    const dateB = new Date(b.fromDate)
    return dateB - dateA
  })

  return (
    <div>
      {/* Contact info on bigger screens */}
      <div key={key} className="hidden md:grid grid-flow-col grid-cols-6">
        <div className=" col-span-2">
          <p className="font-sans text-blue mt-2 mb-0 md:my-2 font-bold">
            {item?.fullName}{" "}
            {item?.numberOfPost > 0 ? (
              <span
                className={
                  selectedMemberId === item?.id
                    ? " font-bold "
                    : " font-normal "
                }
              >
                (
                <span
                  className={
                    selectedMemberId === item?.id
                      ? " font-bold "
                      : " font-normal underline underline-offset-2 "
                  }
                >
                  {item?.numberOfPost} verv
                </span>
                )
              </span>
            ) : null}
          </p>
        </div>
        <div className=" col-span-1 text-center">
          <p
            className={
              (selectedMemberId === item?.id
                ? " font-bold "
                : " font-normal ") + " font-sans text-blue mb-2 md:my-2 "
            }
          >
            {item?.age}
          </p>
        </div>
        <div className=" col-span-2 text-center">
          <p
            className={
              (selectedMemberId === item?.id
                ? " font-bold "
                : " font-normal ") + " font-sans text-blue mb-2 md:my-2 "
            }
          >
            {item?.email}
          </p>
        </div>
        <div className=" col-span-1">
          <p
            className={
              (selectedMemberId === item?.id
                ? " font-bold "
                : " font-normal ") + " font-sans text-blue mb-2 md:my-2 "
            }
          >
            {item?.mobilePhone}
          </p>
        </div>

        {item?.numberOfPost > 0 ? (
          <div
            onClick={openSelectedMember}
            className=" flex items-center cursor-pointer"
          >
            <div>
              {selectedMemberId === item?.id ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        ) : (
          <div className=" w-6 h-6 " />
        )}
      </div>
      {/* Contact info on smaller screens  */}
      <div key={key} className="md:hidden flex flex-row ">
        <div className=" basis-11/12 ">
          <div className="">
            <p className="font-sans text-blue mt-2 mb-0 md:my-2 font-bold">
              {item?.fullName}{" "}
              <span
                className={
                  selectedMemberId === item?.id
                    ? " font-bold "
                    : " font-normal "
                }
              >
                (
                <span
                  className={
                    selectedMemberId === item?.id
                      ? " font-bold "
                      : " font-normal underline underline-offset-2 "
                  }
                >
                  {item?.age}
                  {item?.numberOfPost > 0
                    ? ", " + item?.numberOfPost + " verv"
                    : null}
                </span>
                )
              </span>
            </p>
          </div>
          <div
            className={
              item?.email === undefined
                ? "flex flex-row break-all "
                : "flex flex-row break-all gap-4 "
            }
          >
            <div className=" ">
              <p
                className={
                  (selectedMemberId === item?.id
                    ? " font-bold "
                    : " font-normal ") + " font-sans text-blue mb-2 md:my-2 "
                }
              >
                {item?.email}
              </p>
            </div>
            <div className=" ">
              <p
                className={
                  (selectedMemberId === item?.id
                    ? " font-bold "
                    : " font-normal ") + " font-sans text-blue mb-2 md:my-2 "
                }
              >
                {item?.mobilePhone}
              </p>
            </div>
          </div>
        </div>

        {item?.numberOfPost > 0 ? (
          <div
            onClick={openSelectedMember}
            className=" cursor-pointer basis-1/12 flex justify-center items-center"
          >
            <div>
              {selectedMemberId === item?.id ? <UpArrow /> : <DownArrow />}
            </div>
          </div>
        ) : (
          <div className=" w-6 h-6" />
        )}
      </div>
      {/* Committee info */}
      {selectedMemberId === item?.id ? (
        <div className=" bg-white mt-4 mb-4 p-4 ">
          {/* Info on bigger screens */}
          <div className=" hidden md:flex flex-col">
            <div className=" grid grid-cols-7 justify-between border-b-2 border-blue font-sans font-bold ">
              <div className="grid col-span-2">
                <p className=" mb-2 ">Verv</p>
              </div>
              <div className="grid col-span-2">
                <p className=" mb-2 ">Type komite</p>
              </div>
              <div className="grid col-span-2">
                <p className=" mb-2 ">Lokallag</p>
              </div>
              <div className="grid col-span-1">
                <p className=" mb-2 ">Periode</p>
              </div>
            </div>
            {CommitteeInfo?.data?.map((committee, key) => (
              <div
                key={key}
                className="grid grid-cols-7 justify-between border-b-2 border-blue font-sans gap-1"
              >
                <div className="grid col-span-2 break-all">
                  <p className=" my-1 ">{committee?.role?.roleName}</p>
                </div>
                <div className="grid col-span-2 break-all">
                  <p className=" my-1 ">
                    {committee?.committee?.committeeType?.committeeTypeName}
                  </p>
                </div>
                <div className="grid col-span-2 break-all">
                  <p className=" my-1 ">
                    {committee?.committee?.branchId?.name}
                  </p>
                </div>
                <div className="grid col-span-1">
                  <p className=" my-1 ">
                    {moment(committee?.fromDate).format("YYYY")}-
                    {committee?.toDate === undefined
                      ? "nå"
                      : moment(committee?.toDate).format("YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* Info on smaller screens */}
          <div className=" md:hidden flex flex-col">
            <div className=" border-b-2 border-blue font-sans font-bold ">
              <div className="">
                <p className=" mb-2 ">Verv</p>
              </div>
            </div>
            {CommitteeInfo?.data?.map((committee, key) => (
              <div
                key={key}
                className=" flex flex-col mt-2 border-b-2 border-blue font-sans"
              >
                <div className="">
                  <p className=" mb-1 font-bold ">
                    {committee?.role?.roleName}
                  </p>
                </div>
                <div className="">
                  <p className=" mb-1 ">
                    {committee?.committee?.committeeType?.committeeTypeName}
                  </p>
                </div>
                <div className="">
                  <p className=" mb-1 ">
                    {committee?.committee?.branchId?.name}
                  </p>
                </div>
                <div className="">
                  <p className=" mb-2 ">
                    {moment(committee?.fromDate).format("YYYY")}-
                    {committee?.toDate === undefined
                      ? "nå"
                      : moment(committee?.toDate).format("YYYY")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className=" border-b-2 border-blue "></div>
    </div>
  )
}

export const CommitteeSchema = ({ item, key }) => (
  <div key={key}>
    <div className="flex flex-col py-2 px-4 mt-4 bg-white">
      <p className="font-sans text-blue mb-2 font-bold">
        {item?.fullName} <span className=" font-normal">{item?.pronoun}</span>
      </p>
      <p className=" font-normal font-sans text-blue mb-0">
        Fødselsår:{" "}
        <span className=" font-bold ">
          {moment(item?.birthDate).format("YYYY")}
        </span>
      </p>
      <p className=" font-normal font-sans text-blue mb-0">
        Medlem siden:{" "}
        <span className=" font-bold ">
          {moment(item?.firstRegistreredDate).format("DD.MM.YYYY")}
        </span>
      </p>
      <p className=" font-normal font-sans text-blue mb-0">
        Antall verv i HEF:{" "}
        <span className=" font-bold ">{item?.numberOfPost}</span>
      </p>
    </div>
  </div>
)

{
  /* <div
className="grid grid-flow-row md:grid-flow-col grid-rows-2 md:grid-rows-1 grid-cols-3 md:grid-cols-5 border-b-2 border-blue "
key={i}
>
<div className=" row-start-1 row-end-1 col-span-4 md:col-span-2">
  <p className="font-sans text-blue mt-2 mb-0 md:my-2 font-bold">
    {item?.fullName}
    {item?.numberOfPost > 0 ? (
      <span className=" font-normal"> ({item?.numberOfPost} verv)</span>
    ) : null}
  </p>
</div>
<div className=" row-start-2 md:row-start-1 row-end-2 md:row-end-1 col-span-3 md:col-span-2">
  <p className="font-sans text-blue mb-2 md:my-2 underline underline-offset-2">
    {item?.email}
  </p>
</div>
<div className=" row-start-2 md:row-start-1 row-end-2 md:row-end-1 col-span-1 md:col-span-1">
  <p className="font-sans text-blue mb-2 md:my-2 underline underline-offset-2">
    {item?.mobilePhone}
  </p>
</div>
</div> */
}
