import React from "react"

export const EmptyCheckbox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      stroke="#004C97"
      stroke-width="2"
    />
  </svg>
)
