import { useMsal } from "@azure/msal-react"
import axios from "axios"
import { navigate } from "gatsby"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { useQueryClient, QueryClient, useMutation, useQuery } from "react-query"
import { authToken } from "../Security/authToken"
import { Post } from "./Types"

export const GetProfile = (account, inProgress, instance) => {
  return useQuery(
    "profile",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/me", {
            headers: {
              "Conent-Type": "application/json",
              // "Ocp-Apim-Subscription-Key":
              //   process.env.GATSBY_APP_SUBSCRIPTION_KEY,
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization: "Bearer " + token,
            },
          })
          .then(res => {
            return res.data
          })
      } else {
        return null
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: (count, error) => {
        if (count >= 3) {
          navigate("/error")
        }
        return true
      },
    }
  )
}

export const GetIncomingSubscriptions = (account, inProgress, instance) =>
  useQuery(
    "getIncomingSubscription",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/CheckSubscription",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  )

export const GetSubscriptions = (account, inProgress, instance) =>
  useQuery(
    "getSubscription",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSubscription/MySubscriptions",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  )

export const GetMembership = (account, inProgress, instance) =>
  useQuery(
    "checkMembership",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/CheckMembership", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      }
    },
    { refetchOnWindowFocus: false }
  )

export const GetActivities = (account, inProgress, instance, seeSsnExist) =>
  useQuery(
    "myActivities",
    async () => {
      //await new Promise(resolve => setTimeout(resolve, 500))
      return axios
        .get(process.env.GATSBY_APP_WEBAPI + "HefCeremonies/me", {
          headers: {
            "Conent-Type": "application/json",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(account, inProgress, instance)),
          },
        })
        .then(res => res.data)
    },
    {
      refetchOnWindowFocus: false,
      enabled: seeSsnExist,
      retry: 3,
    }
  )

export const GetPortalSecurity = (account, inProgress, instance) => {
  return useQuery(
    "portalSecurity",
    async () => {
      const token = await authToken(account, inProgress, instance)
      //await new Promise(resolve => setTimeout(resolve, 500))
      if (token?.length > 0) {
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefContacts/PortalSecurity", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization: "Bearer " + token,
            },
          })
          .then(res => {
            return res.data
          })
      } else {
        return null
      }
    },
    { refetchOnWindowFocus: false }
  )
}

export const GetQueries = (account, inProgress, instance) =>
  useQuery(
    "getQueries",
    async () => {
      //await new Promise(resolve => setTimeout(resolve, 500))
      return axios
        .get(process.env.GATSBY_APP_WEBAPI + "HefQueries", {
          headers: {
            "Conent-Type": "application/json",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(account, inProgress, instance)),
          },
        })
        .then(res => res.data)
    },
    {
      // enabled: seeActivePosts,
      refetchOnWindowFocus: false,
      retry: (count, error) => {
        if (count >= 3) {
          // setLoadingError(true)
          return false
        }
        return true
      },
    }
  )

export const Gender = genderCode => {
  let gender = ""
  genderCode == 1
    ? (gender = "Mann")
    : genderCode == 2
    ? (gender = "Kvinne")
    : genderCode == 778380000
    ? (gender = "Ikke-kjønnet")
    : genderCode == 778380001
    ? (gender = "Kjønnsskeiv")
    : genderCode == 778380002
    ? (gender = "Ikke-binær")
    : (gender = "Ikke oppgitt")
  return gender
}
